import { useContext } from "react"
import { VisibilityContext } from "react-horizontal-scrolling-menu"
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons"
import SimilarProgramsSliderArrow from "./SimilarProgramsSliderArrow"

const SimilarProgramsSliderArrowRight = () => {
  const { scrollNext } = useContext(VisibilityContext)

  return (
    <SimilarProgramsSliderArrow
      onClick={() => scrollNext()}
      icon={faArrowRight}
    />
  )
}

export default SimilarProgramsSliderArrowRight
