import ScoreCardScoreNumber from "./ScoreCardScoreNumber"
import ScoreCardScoreBar from "./ScoreCardScoreBar"
import ScoreCardScoreBarLegend from "./ScoreCardScoreBarLegend"

interface ScoreCardScoreProps {
  currentScore: number | null
  maxScore: number | null
  scoreColorClass: string
  hasScoreBarLegend: boolean
}

const ScoreCardScore = ({
  currentScore,
  maxScore,
  scoreColorClass,
  hasScoreBarLegend,
}: ScoreCardScoreProps) => {
  const normalizedCurrentScore = Math.min(Math.max(currentScore ?? 0, 0), 10)
  const normalizedMaxScore = Math.min(Math.max(maxScore ?? 0, 0), 10)

  // DEV: Calculate heights as percentages of the total bar height
  const currentScoreHeight = (normalizedCurrentScore / 10) * 100
  const maxScorePosition = (normalizedMaxScore / 10) * 100

  return (
    <div className="flex items-center">
      <ScoreCardScoreNumber
        scoreColorClass={scoreColorClass}
        currentScore={Math.round(currentScore || 0)}
      />

      <ScoreCardScoreBar
        currentScoreHeight={currentScoreHeight}
        maxScorePosition={maxScorePosition}
        scoreColorClass={scoreColorClass}
        hidePotentialScore={!!maxScore && maxScore === 10}
      />

      <ScoreCardScoreBarLegend
        hasScoreBarLegend={hasScoreBarLegend}
        currentScoreHeight={currentScoreHeight}
        maxScorePosition={maxScorePosition}
        hidePotentialScore={!!maxScore && maxScore === 10}
      />
    </div>
  )
}

export default ScoreCardScore
