import OnbordingOverviewAsset from "./OnbordingOverviewAsset"
import BLOB from "../../../images/blob-light.png"
import FOREST_CARBON from "../../../images/onboarding-overview-forest-carbon-lg.svg"

const OnboardingOverviewScoresImage = () => {
  return (
    <div className="relative w-auto max-w-[528px] h-auto block self-start mt-[27px] lg:mt-0 mx-auto lg:mx-0">
      <img src={BLOB} alt="blob" width={504} height={482} />

      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ">
        <OnbordingOverviewAsset
          image={FOREST_CARBON}
          text="Forest Carbon"
          className="md:gap-[22px]"
          textClass="-translate-x-4"
        />
      </div>
    </div>
  )
}

export default OnboardingOverviewScoresImage
