import PropertyMap from "../PropertyMap"
import { useAccountId } from "../../hooks"
import { ChangeDataType } from "@/types/property"
import ThreatsMapLegend from "./ThreatsMapLegend"
import { TileURLsTypes } from "@/types/tiles"
import ThreatsMapOverlay from "./ThreatsMapOverlay"

interface ThreatsMapProps {
  changeData: ChangeDataType | null | undefined
  changeDetectionData: TileURLsTypes | undefined
  isMember: boolean | undefined
}

const ThreatsMap = ({
  changeData,
  changeDetectionData,
  isMember,
}: ThreatsMapProps) => {
  const accountId = useAccountId()

  // there is an assessment but no acreage data
  const noDetections =
    changeData !== null &&
    (changeData?.major_loss === 0 || changeData?.major_loss === null) &&
    (changeData?.minor_loss === 0 || changeData?.minor_loss === null)

  // if change_data is null, we haven't run assessment yet
  const dataNotReady = changeData === null || changeData === undefined

  return (
    <div className="lg:grow">
      <PropertyMap
        accountId={accountId}
        tileData={changeDetectionData}
        legend={<ThreatsMapLegend />}
        overlay={
          <ThreatsMapOverlay
            isMember={isMember}
            noDetections={noDetections}
            dataNotReady={dataNotReady}
          />
        }
        enableZoomPan
      />

      <p className="italic">
        Losses depicted on this map were detected up to 1 month prior to the
        detection date.
      </p>
    </div>
  )
}

export default ThreatsMap
