import {
  Popover as PopoverBase,
  PopoverDisclosure,
  PopoverArrow,
  PopoverStateReturn,
} from "reakit/Popover"
import cx from "classnames"
import { ReactNode } from "react"

export interface PopoverTypes {
  popover: PopoverStateReturn
  label: string
  content?: ReactNode
  className?: string
  children: ReactNode
}

const Popover = ({
  popover,
  label = "Popover",
  className,
  children,
  content,
}: PopoverTypes) => (
  <>
    <PopoverDisclosure
      {...popover}
      className="popover-disclosure"
      data-testid="popover-disclosure"
    >
      {content || label}
    </PopoverDisclosure>

    {popover.visible ? (
      <PopoverBase
        {...popover}
        tabIndex={0}
        aria-label={label}
        className={cx("popover", className)}
      >
        <PopoverArrow {...popover} size={20} />
        {children}
      </PopoverBase>
    ) : null}
  </>
)

export default Popover
