import { useEffect, useMemo, useState } from "react"
import { useQueryClient } from "@tanstack/react-query"

import DashboardLayout from "../_layouts/Dashboard"
import FireworksConfetti from "../../components/FireworksConfetti"
import DashboardSubNavigation from "../../sections/ViewAccount/DashboardSubNavigation"
import ViewAccountContent from "../../sections/ViewAccount/ViewAccountContent"
import { getProjectsData } from "../../shared/utils"
import {
  useAccountId,
  useProjectList,
  useAccount,
  useAccountProperty,
  useAccountNotifications,
  useProfile,
  useDashboardAccounts,
  useIsMultiAccount,
  useGetAttestationQuizes,
  useAccountInterest,
} from "../../hooks"
import { useIsCheckoutSuccess } from "../../hooks/useIsCheckoutSuccess"
import { AccountTypes } from "@/types/account"
import { Profile } from "@/types"
import { NotificationTypes } from "@/types/notifications"
import { AccountProperty } from "@/types/property"
import { EligibilityQuizzes } from "@/types/eligibility"
import { AccountInterestTypes } from "@/types/accountInterest"
import { DashboardAccountsTypes } from "@/types/dashboardAccounts"
import { ProjectDataTypes } from "@/types/program"
import { ProjectListTypes } from "@/types/program"

const ViewAccount = ({ showGlobalNotifications = false }) => {
  const queryClient = useQueryClient()
  const isMultiAccount = useIsMultiAccount()
  const accountId = useAccountId()
  const isCheckoutSuccess = useIsCheckoutSuccess()
  const [showSurveyNotification, setShowSurveyNotification] =
    useState<boolean>(false)

  // Load our data
  const { data: projectList, isLoading: projectListIsLoading } = useProjectList<
    ProjectListTypes,
    Error
  >(queryClient, accountId, {})

  const programsData: ProjectDataTypes[] = useMemo(() => {
    return getProjectsData(projectList)
  }, [projectList])

  const { data: account, isLoading: accountIsLoading } = useAccount<
    AccountTypes,
    Error
  >(queryClient, accountId)

  const { data: profile, isLoading: profileIsLoading } = useProfile<
    Profile,
    Error
  >(queryClient)

  const { data: notifications, isLoading: notificationsIsLoading } =
    useAccountNotifications<NotificationTypes[], Error>(queryClient, accountId)

  const { data: property, isLoading: propertyIsLoading } = useAccountProperty<
    AccountProperty,
    Error
  >(queryClient, accountId, {
    refetchInterval: (query) => {
      if (query.state.data?.landcover_data === null) {
        return 1000
      }

      return false
    },
  })

  const { data: accountInterestData, isLoading: accountInterestIsLoading } =
    useAccountInterest<AccountInterestTypes, Error>(queryClient, accountId)

  const { data: quizzesData, isLoading: quizDataIsLoading } =
    useGetAttestationQuizes<EligibilityQuizzes, Error>(accountId)

  const { data: accounts, isLoading: accountsIsLoading } = useDashboardAccounts<
    DashboardAccountsTypes,
    Error
  >(queryClient)

  const handleShowSurveyNotification = (newValue: boolean) => {
    setShowSurveyNotification(newValue)
  }

  const dataLoaded = useMemo(() => {
    return !!(
      account &&
      accounts &&
      property &&
      notifications &&
      profile &&
      projectList &&
      quizzesData &&
      accountInterestData
    )
  }, [
    account,
    accounts,
    property,
    notifications,
    profile,
    projectList,
    quizzesData,
    accountInterestData,
  ])

  useEffect(() => {
    if (dataLoaded && !accountInterestData?.has_answered_all_questions) {
      setShowSurveyNotification(true)
    }
  }, [dataLoaded, accountInterestData])

  return (
    <DashboardLayout
      showGlobalNotifications={showGlobalNotifications}
      isLoading={[
        accountIsLoading,
        accountsIsLoading,
        propertyIsLoading,
        notificationsIsLoading,
        profileIsLoading,
        projectListIsLoading,
        quizDataIsLoading,
        accountInterestIsLoading,
      ]}
      subNavigation={
        <DashboardSubNavigation
          show={dataLoaded && isMultiAccount && accountId !== "_single"}
          title={account?.account_name}
        />
      }
    >
      <ViewAccountContent
        account={account}
        accounts={accounts}
        notifications={notifications}
        showSurveyNotification={showSurveyNotification}
        accountInterestData={accountInterestData}
        handleShowSurveyNotification={handleShowSurveyNotification}
        property={property}
        quizzesData={quizzesData}
        profile={profile}
        isCheckoutSuccess={isCheckoutSuccess}
        programsData={programsData}
      />

      <FireworksConfetti show={isCheckoutSuccess} speed={3} duration={3000} />
    </DashboardLayout>
  )
}

export default ViewAccount
