import { CloseButton } from "../CloseButton"

export interface StickyNotificationCloseProps {
  onDismiss?: () => void
}

const StickyNotificationClose = ({
  onDismiss,
}: StickyNotificationCloseProps) => {
  if (!onDismiss) {
    return null
  }

  return (
    <div
      className="absolute top-3 right-3"
      data-testid="sticky-notification-close"
    >
      <CloseButton
        aria-label="Dismiss notification"
        onClick={onDismiss}
        white
      />
    </div>
  )
}

export default StickyNotificationClose
