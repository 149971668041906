interface ScoreCardTypes {
  heading: string
  subheading: (rating_string: string) => string
  description: string
  potential_template: (rating_string: string, rating_numeric: number) => string
  actions: string
  tradeoffs: string
  programs: string
  guideURL: string | null
}

export const SCORE_CARDS: Record<string, ScoreCardTypes> = {
  carbon_potential: {
    heading: "Forest Carbon",
    subheading: (rating_string) => `${rating_string} carbon storage.`,
    description: "Amount of carbon stored in your woods.",
    potential_template: (rating_string, rating_numeric) =>
      `Your property has the potential for ${rating_string} (${rating_numeric}) carbon storage.<br/><br/>Every acre has a natural limit of the total amount of living wood and carbon storage it can support which is set by the soil and climate. Places with fertile soil and good rainfall can naturally hold more carbon than places with poor soils and low rainfall.`,
    actions:
      "Preventing disturbances like forest pests, fire, and reducing harvest activity allows your woods to keep growing and results in higher forest carbon on your land. ",
    tradeoffs:
      "High carbon storage can put stress on trees that have to fight for growing space, which increases risk and decreases your forest health score.",
    programs:
      "Scores over 5 have a good chance of inclusion in forest carbon programs and the potential to produce carbon credits.",
    guideURL: "https://ncx.com/forest-carbon-guide/",
  },
  forest_health: {
    heading: "Forest Health",
    subheading: (rating_string) => `${rating_string} forest health.`,
    description: "Resilience to disturbances like pest, pathogens, and fire.",
    potential_template: (rating_string, rating_numeric) =>
      `Your property has the potential for ${rating_string} (${rating_numeric}) forest health.`,
    actions:
      "Thinning to reduce density and increase  diversity of tree species and size increases resilience to many forest health risks like insects and fire.",
    tradeoffs:
      "Increasing forest health scores often means reducing the density of stems which will lower timber and carbon scores.",
    programs:
      "Scores under 6 should strongly consider looking into government assistance programs to improve their forest health.",
    guideURL: null,
  },
  timber_volume: {
    heading: "Timber",
    subheading: (rating_string) => `${rating_string} timber volume.`,
    description: "Volume of merchantable pulp and sawtimber sized trees.",
    potential_template: (rating_string, rating_numeric) =>
      `Your property has the potential for ${rating_string} (${rating_numeric}) timber volume.<br/><br/>The mix of tree species possible on your property, fertility of your soil, and climatic zone define how much merchantable timber is possible to be grown on your property. `,
    actions:
      "Thinning out non-merchantable species leaving growing space for merchantable tree species has the potential to increase your timber score over time.",
    tradeoffs:
      "High timber stocking can put stress on trees that have to fight for growing space, which increases risks and decreases your forest health score.",
    programs:
      "Properties with merchantable timber must also consider market access which impacts potential income.",
    guideURL: "https://ncx.com/timber-guide",
  },
}

export const SCORE_CARD_RATINGS = {
  0: "Negilible",
  1: "Minimal",
  2: "Very Low",
  3: "Low",
  4: "Some",
  5: "Moderate",
  6: "Considerable",
  7: "High",
  8: "Very High",
  9: "Excellent",
  10: "The Maximum",
}
