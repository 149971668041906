import { ReactNode } from "react"

interface PropertyMapContainerProps {
  children: ReactNode
}

const PropertyMapContainer = ({ children }: PropertyMapContainerProps) => (
  <div className="card card-content shadow-none relative my-4 p-0">
    <div className="relative xl:flex rounded overflow-hidden">{children}</div>
  </div>
)

export default PropertyMapContainer
