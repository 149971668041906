import { ReactNode, useEffect, useState } from "react"
import mapboxglSupported from "@mapbox/mapbox-gl-supported"

import MapboxGLNotSupported from "../../components/MapVisualization/MapboxGLNotSupported"
import ProjectMapVisualization from "../ProjectDetails/ProjectMap/ProjectMapVisualization"
import PropertyMapErrorMessage from "./PropertyMapErrorMessage"
import PropertyMapContainer from "./PropertyMapContainer"
import ProjectMapParcels from "../ProjectDetails/ProjectMap/ProjectMapParcels"
import ProjectMapSkeleton from "../ProjectDetails/ProjectMap/ProjectMapSkeleton"
import { getViewportFromFeature } from "../ProjectDetails/ProjectMap/helpers"
import { useGetParcelGeoms } from "../../hooks/index"
import { handleNextFeature, handlePrevFeature } from "./helpers"
import { FeatureCollection, Geometry, Properties } from "@turf/helpers"
import { ViewportTypes } from "@/types"
import { TileURLsTypes } from "@/types/tiles"

interface PropertyMapTypes {
  accountId: string
  legend?: ReactNode
  overlay?: ReactNode
  enableZoomPan?: boolean
  tileData?: TileURLsTypes | undefined
  heapEvent?: string
}

// This is similar to ProjectMap except removing project-related eligibility elements
const PropertyMap = ({
  accountId,
  enableZoomPan = false,
  overlay,
  legend,
  tileData,
  heapEvent,
}: PropertyMapTypes) => {
  const [activeFeature, setActiveFeature] = useState<number | null>(0)
  const [viewport, setViewport] = useState<ViewportTypes | null>(null)

  // DEV: we only care about parcel data because this isn't about eligibility, just a parcel viewer
  const {
    data: parcelData,
    isLoading: parcelIsLoading,
    isError: isParcelError,
  } = useGetParcelGeoms<FeatureCollection<Geometry, Properties>, Error>(
    accountId
  )

  const handleSetViewport = (newViewport: ViewportTypes | null) => {
    setViewport(newViewport)
  }

  const hasMultipleParcels: boolean =
    Array.isArray(parcelData?.features) && parcelData?.features.length > 1

  useEffect(() => {
    if (parcelData !== undefined) {
      setViewport(getViewportFromFeature(parcelData?.features[0]))
    }
  }, [parcelData])

  if (!mapboxglSupported.supported()) {
    const reason = mapboxglSupported.notSupportedReason()

    return (
      <div className="container xl:max-w-1120 mt-[66px]">
        <div className="lg:max-w-[844px] h-[400px] lg:flex relative rounded bg-gray2 overflow-hidden p-6">
          <MapboxGLNotSupported reason={reason} />
        </div>
      </div>
    )
  }

  if (parcelIsLoading || !parcelData || isParcelError) {
    return (
      <PropertyMapContainer>
        <ProjectMapSkeleton
          animate
          message={<PropertyMapErrorMessage isError={isParcelError} />}
        />
      </PropertyMapContainer>
    )
  }

  return (
    <PropertyMapContainer>
      <ProjectMapVisualization
        viewport={viewport}
        setViewport={handleSetViewport}
        parcelData={parcelData}
        tileData={tileData}
        activeFeature={activeFeature}
        isIneligible={false}
        enableZoomPan={enableZoomPan}
        legend={legend}
        overlay={overlay}
        heapEvent={heapEvent}
      />

      <ProjectMapParcels
        show={hasMultipleParcels}
        isIneligible={false}
        parcelData={parcelData}
        activeFeature={activeFeature}
        setActiveFeature={setActiveFeature}
        setViewport={handleSetViewport}
        onNextFeatureClick={handleNextFeature}
        onPrevFeatureClick={handlePrevFeature}
        enableEligibility={false}
        className="property-map-landcover-report"
      />
    </PropertyMapContainer>
  )
}

export default PropertyMap
