import cx from "classnames"

interface ScoreCardScoreNumberProps {
  scoreColorClass: string
  currentScore: number | null
}

const ScoreCardScoreNumber = ({
  scoreColorClass,
  currentScore,
}: ScoreCardScoreNumberProps) => (
  <div
    className={cx(
      "flex items-center justify-center rounded w-9 h-9 text-white text-lg font-bold leading-[140%] tracking-0.36 mr-4",
      scoreColorClass
    )}
  >
    {currentScore ?? 0}
  </div>
)

export default ScoreCardScoreNumber
