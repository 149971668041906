export interface StickyNotificationTitleProps {
  title: string | null
}

const StickyNotificationTitle = ({ title }: StickyNotificationTitleProps) => {
  if (!title) {
    return null
  }

  return (
    <div className="mb-2 pr-3">
      <strong className="font-semibold">{title}</strong>
    </div>
  )
}

export default StickyNotificationTitle
