import { Form } from "formik"

import { ActionPermissionWrapper } from "../../components/ActionPermissionWrapper"
import { SubmitButton } from "../../components/SubmitButton"
import { WrappedInput } from "../../components/Input"
import PreferredContactInput from "../../components/PreferredContactInput"
import PhoneInput from "./PhoneInput"
import QuestionExamples from "./QuestionExamples"
import { needsPhoneValidation } from "./helpers"
import { FormComponentTypes } from "./types"

const AskAQuestionFormComponent = ({
  formikProps,
  account,
  isSubmitting,
}: FormComponentTypes) => {
  const showPhoneField = needsPhoneValidation(
    formikProps.values.preferred_contact_method
  )

  const charsRemaining = 100 - formikProps.values.question.length

  return (
    <Form className="inline">
      <div>
        <WrappedInput
          labelClass="label font-bold mb-2 leading-18px"
          aria-label="Question"
          label={`Your question (${charsRemaining} characters remaining)`}
          placeholder="Ask a question"
          type="text"
          name="question"
          className="border-charcoal-50 mb-4"
          maxLength={100}
        />
      </div>

      <div>
        <PreferredContactInput />
      </div>

      <PhoneInput showPhoneField={showPhoneField} />

      <QuestionExamples />

      <ActionPermissionWrapper accountRole={account?.role} action="editAccount">
        <SubmitButton
          isSubmitting={isSubmitting}
          className="btn2 btn2-primary font-semibold ask-a-question-submit"
        >
          Submit
        </SubmitButton>
      </ActionPermissionWrapper>
    </Form>
  )
}

export default AskAQuestionFormComponent
