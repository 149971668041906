import { useEffect, useRef } from "react"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"

import { Modal } from "../../../../components/Modal"
import { DialogStateReturn } from "reakit"

interface LandCoverClassesModalTypes {
  dialog: DialogStateReturn
}

const LandCoverClassesModal = ({ dialog }: LandCoverClassesModalTypes) => {
  const scrollRef = useRef<HTMLDivElement | null>(null)

  // DEV: Fix dialog body scrolling issue on iOS
  useEffect(() => {
    const scrollBox = scrollRef.current
    if (scrollBox && dialog.visible) {
      disableBodyScroll(scrollBox)
    }
    return () => {
      if (scrollBox) {
        enableBodyScroll(scrollBox)
      }
    }
  }, [dialog.visible])

  return (
    <Modal
      header="How is land cover classified?"
      aria-label="How land cover is classified"
      dialog={dialog}
      className="p-6"
    >
      <div
        ref={scrollRef}
        className="overflow-y-auto max-h-[236px] mb-6 space-y-p"
      >
        <p className="text-base text-charcoal-500 leading-130 tracking-0.32 mb-4">
          Land is classified into 1 of 8 categories by the{" "}
          <a
            className="link"
            href="https://www.mrlc.gov/data/legends/national-land-cover-database-class-legend-and-description"
            target="_blank"
            rel="noopener noreferrer"
          >
            United States Geological Survey
          </a>
          : Forested, Cultivated, Grassland, Shrubland, Water, Wetlands,
          Developed, and Barren
        </p>
        <p className="text-charcoal-500 text-base leading-130 tracking-0.32">
          <b>Forested - </b> area dominated by trees
        </p>
        <p className="text-charcoal-500 text-base leading-130 tracking-0.32">
          <b>Cultivated - </b> area of grasses, legumes, hays and crops
          cultivated for livestock or human consumption
        </p>
        <p className="text-charcoal-500 text-base leading-130 tracking-0.32">
          <b>Grassland - </b> area dominated by vegetation like grasses,
          typically suitable for grazing
        </p>
        <p className="text-charcoal-500 text-base leading-130 tracking-0.32">
          <b>Shrubland - </b> area dominated by shrubs, less than 5 meters tall.
          Includes young trees and trees stunted by environmental conditions
        </p>
        <p className="text-charcoal-500 text-base leading-130 tracking-0.32">
          <b>Water - </b> open water
        </p>
        <p className="text-charcoal-500 text-base leading-130 tracking-0.32">
          <b>Wetlands - </b> areas which consist of some vegetation but are
          periodically saturated or covered by water
        </p>
        <p className="text-charcoal-500 text-base leading-130 tracking-0.32">
          <b>Developed - </b> areas with constructed materials and some
          vegetation like grass lawns
        </p>
        <p className="text-charcoal-500 text-base leading-130 tracking-0.32">
          <b>Barren - </b> areas of bedrock, desert pavement, strip mines,
          gravel and other accumulations of minerals
        </p>
      </div>

      <div className="-ml-2 -mr-2 -mb-2 pt-4">
        <button
          type="button"
          className="btn2 btn2-primary w-full md:w-auto font-semibold leading-130"
          onClick={dialog.hide}
        >
          Got It
        </button>
      </div>
    </Modal>
  )
}

export default LandCoverClassesModal
