import { Dispatch, RefObject, SetStateAction } from "react"
import { Dialog, DialogBackdrop, DialogStateReturn } from "reakit"

import { useSessionStore } from "../../stores"

interface ThreatsMapOverlayDialogProps {
  dialog: DialogStateReturn
  visible: boolean
  dataNotReady: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
}

const ThreatsMapOverlayDialog = ({
  dialog,
  visible,
  dataNotReady,
  setVisible,
}: ThreatsMapOverlayDialogProps) => {
  const { ncxLogoRef } = useSessionStore()
  const label = dataNotReady ? "Data is pending" : "No recent detections"

  return (
    <div className="relative w-full h-full z-[2] pointer-events-none">
      <DialogBackdrop
        {...dialog}
        visible={visible}
        className="flex justify-center items-center w-full h-full bg-dusk-500/75 pointer-events-auto"
      >
        <Dialog
          {...dialog}
          visible={visible}
          aria-label={label}
          className="st-dialog opacity-100 w-full max-w-[397px]"
          style={{
            position: "static",
            transform: "none",
            zIndex: 2,
          }}
          unstable_finalFocusRef={ncxLogoRef as RefObject<HTMLElement>}
        >
          <h4 className="text-charcoal-500 text-lg font-body font-bold leading-[140%] tracking-0.36">
            {label}
          </h4>

          <p className="text-charcoal-500 text-base font-body leading-[140%] tracking-0.32 mt-4">
            {dataNotReady
              ? "We are still calculating specific acres of loss. Dismiss this alert to explore loss detection in your area now."
              : "We'll alert you via email when we detect a new confirmed loss. Dismiss this alert to explore loss detection in your area."}
          </p>

          <button
            className="btn2 btn2-primary text-base font-body mt-3"
            onClick={() => {
              setVisible((oldVisible) => !oldVisible)
            }}
          >
            Dismiss
          </button>
        </Dialog>
      </DialogBackdrop>
    </div>
  )
}

export default ThreatsMapOverlayDialog
