import { ReactNode } from "react"
import cx from "classnames"

import IconCard from "./IconCard"

export interface ErrorCardProps {
  className?: string
  children: ReactNode
}

const ErrorCard = ({ className, children }: ErrorCardProps) => (
  <IconCard
    className={cx("card-error", className)}
    icon={<i className="far fa-times-circle" />}
    dataTest="error-card"
  >
    {children}
  </IconCard>
)

export default ErrorCard
