import { ReactNode } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLightbulbOn } from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"

export interface HelperCardTypes {
  children: ReactNode
  className: string
}

const HelperCard = ({ children, className }: HelperCardTypes) => (
  <div
    className={cx(
      "rounded bg-cloud-500 border border-solid border-dusk-50 p-6",
      className
    )}
    data-testid="helper-card"
  >
    <div className="flex">
      <div className="flex items-center shrink-0 text-leaf px-1">
        <FontAwesomeIcon icon={faLightbulbOn} title={faLightbulbOn.iconName} />
      </div>
      <div className="ml-2">{children}</div>
    </div>
  </div>
)

export default HelperCard
