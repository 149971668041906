import ScoreCardScoreNumber from "./ScoreCardScoreNumber"

interface ScoreCardStateComparisonScore {
  scoreColorClass: string
  score: number | null
  text: string
}

const ScoreCardStateComparisonScore = ({
  scoreColorClass,
  score,
  text,
}: ScoreCardStateComparisonScore) => (
  <div className="flex items-center">
    <ScoreCardScoreNumber
      scoreColorClass={scoreColorClass}
      currentScore={score}
    />

    <p className="text-dusk-700 text-xs text-left font-body leading-130 tracking-0.36 w-min">
      {text}
    </p>
  </div>
)

export default ScoreCardStateComparisonScore
