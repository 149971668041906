import { Dispatch, SetStateAction, useState } from "react"
import { Link, useLocation } from "react-router"
import { Form, FormikProps } from "formik"

import ErrorCard from "../../components/ErrorCard"
import { SubmitButton } from "../../components/SubmitButton"
import { WrappedInput } from "../../components/Input"
import { AUTH_STEPS } from "./constants"
import NCX from "../../images/ncx-login-logo.png"
import { AuthDataTypes } from "@/types/auth"

interface EmailLoginFormComponentTypes extends FormikProps<AuthDataTypes> {
  setAuthData: Dispatch<SetStateAction<AuthDataTypes>>
  email: string
  errorMessage: string | null
}

const EmailLoginFormComponent = ({
  setAuthData,
  email,
  values,
  errorMessage,
  isSubmitting,
}: EmailLoginFormComponentTypes) => {
  const [showPassword, setShowPassword] = useState(false)
  const location = useLocation()

  function handleBlur() {
    setAuthData({
      email: email,
      password: values.password,
    })
  }

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <>
      <div className="flex justify-center mb-4">
        <img
          src={NCX}
          alt="NCX Logo"
          className="block w-auto h-[51px] lg:h-[46px]"
        />
      </div>

      <h2 className="text-center text-lg leading-130 tracking-0.378 mb-2">
        <span>Welcome Back</span>
      </h2>

      <p className="text-center text-base leading-130 tracking-0.32 mb-4">
        Log in to explore programs
      </p>

      <Form className="space-y-4" onBlur={handleBlur}>
        {errorMessage && <ErrorCard>{errorMessage}</ErrorCard>}
        <div className="space-y-4">
          <div>
            {/* DEV: If spacing was on outer div, then it'd overspace Link from error message*/}
            <div className="space-y-1">
              <WrappedInput
                data-test="input-password"
                label="Password"
                name="password"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                as="password"
                toggleShowPassword={toggleShowPassword}
              />
            </div>
            <div>
              <Link
                className="text-sm text-leaf link--underline-only"
                to={location.pathname}
                state={{
                  ...location.state,
                  authStep: AUTH_STEPS.resetPassword,
                }}
              >
                Forgot your password?
              </Link>
            </div>
          </div>

          <SubmitButton
            className="btn2 btn2-primary btn2-block font-semibold"
            isSubmitting={isSubmitting}
            data-test="email-log-in"
          >
            Log In
          </SubmitButton>
        </div>

        <hr className="border-charcoal-50" />

        <div className="text-center text-dusk">
          Need help?{" "}
          <a
            className="link--underline-only text-base text-dusk-500"
            href="mailto:landowners@ncx.com"
            data-test="having-trouble"
          >
            <span className="link">landowners@ncx.com</span>
          </a>
        </div>
      </Form>
    </>
  )
}

export default EmailLoginFormComponent
