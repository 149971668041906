import { ReactNode } from "react"
import { Link } from "react-router"
import cx from "classnames"

export interface NumericPaginationPageLinkTypes {
  children: ReactNode
  linkType: "dots" | "current" | "disabled" | "default"
  page: number | string
  className: string
  urlRoot: string
  ariaLabel?: string
  queryParams?: boolean
}

const NumericPaginationPageLink = ({
  children,
  linkType,
  page,
  className,
  urlRoot,
  ariaLabel,
  queryParams,
}: NumericPaginationPageLinkTypes) => {
  const pageLinkTypes = {
    dots: (
      <span className="np-dots" aria-label="Dots">
        {children}
      </span>
    ),
    disabled: (
      <span className="np-disabled" aria-label="Disabled">
        {children}
      </span>
    ),
    current: (
      <span className="np-current" aria-label="Current">
        {children}
      </span>
    ),
    default: (
      <Link
        to={`${urlRoot}${queryParams ? "?page=" : "/"}${page}`}
        className={cx("np-link", className)}
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        aria-label={ariaLabel ? ariaLabel : `Go to page ${children}`}
      >
        {children}
      </Link>
    ),
  }
  return pageLinkTypes[linkType] || pageLinkTypes.default
}

export default NumericPaginationPageLink
