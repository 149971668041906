import { ReactNode } from "react"
import cx from "classnames"

interface OnboardingOverviewContainerProps {
  children: ReactNode
  className?: string
}

const OnboardingOverviewContainer = ({
  children,
  className,
}: OnboardingOverviewContainerProps) => (
  <div className={cx("container max-w-[1424px] px-4 md:px-16", className)}>
    {children}
  </div>
)

export default OnboardingOverviewContainer
