import { CSSProperties } from "react"
import { Link } from "react-router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/pro-duotone-svg-icons"

import { useAccountUrlPrefix } from "../../hooks"

const AskAQuestionClose = () => {
  const accountUrlPrefix = useAccountUrlPrefix()

  return (
    <div className="flex justify-end px-4 sm:px-[72px] py-4 sm:pt-12 sm:pb-[76px]">
      <Link
        to={`${accountUrlPrefix}/`}
        className="ask-a-question-close text-charcoal-900"
      >
        <FontAwesomeIcon
          icon={faXmark}
          style={
            {
              "--fa-primary-opacity": 0,
              "--fa-secondary-opacity": 1,
            } as CSSProperties
          }
        />
      </Link>
    </div>
  )
}

export default AskAQuestionClose
