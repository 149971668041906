import { useState, useRef, useEffect, ReactNode } from "react"
import { Link } from "react-router"
import cx from "classnames"

import { useIsOverflow } from "../hooks/useIsOverflow"

export interface ExpandableContainerProps {
  id: string
  btnTextOpen: string
  btnTextClose: string
  className?: string
  containerHeight?: number
  children: ReactNode
  overflowDisabled?: boolean
  overrideUrl?: string
}

const CONTAINER_HEIGHT = 492

const ExpandableContainer = ({
  id,
  children,
  btnTextOpen,
  btnTextClose,
  containerHeight = CONTAINER_HEIGHT,
  overflowDisabled = false,
  className,
  overrideUrl,
}: ExpandableContainerProps) => {
  const [expanded, setExpanded] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const { isOverflow, scrollHeight } = useIsOverflow(ref, containerHeight)

  useEffect(() => {
    setExpanded(false)
  }, [id])

  return (
    <div className={cx("relative", className)}>
      <div
        ref={ref}
        className="overflow-hidden h-auto transition-max-height duration-300 ease-in-out"
        // DEV: we use style instead of tailwind classes here in order to animate the container height from fixed to its dynamic scrollHeight value
        style={{
          maxHeight: expanded ? scrollHeight : containerHeight,
        }}
      >
        {children}
      </div>
      {!expanded && isOverflow && (
        <div className="h-32 absolute bottom-14 left-0 right-0 bg-gradient-to-t from-cloud-50 to-transparent expandable-container-unexpanded"></div>
      )}
      {isOverflow && (
        <div className="text-center mt-8">
          {overrideUrl ? (
            <Link
              to={overrideUrl}
              className={cx("text-leaf font-semibold focus:outline-none", {
                "hover:underline": !overflowDisabled,
              })}
              state={{ scrollToTop: true }}
            >
              {btnTextOpen}
            </Link>
          ) : (
            <button
              type="button"
              className={cx("text-leaf font-semibold focus:outline-none", {
                "hover:underline": !overflowDisabled,
              })}
              onClick={() => setExpanded((state) => !state)}
              disabled={overflowDisabled}
            >
              {expanded ? btnTextClose : btnTextOpen}
            </button>
          )}
        </div>
      )}
    </div>
  )
}

export default ExpandableContainer
