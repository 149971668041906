import cx from "classnames"

import { PROJECT_BADGE_TYPES } from "../../shared/constants"
import { ProjectBadgeType } from "../../types/constants"

export interface ProjectBadgeAnimationTypes {
  type: ProjectBadgeType
}

const ProjectBadgeAnimation = ({ type }: ProjectBadgeAnimationTypes) => (
  <div
    className="project-badge-animation"
    data-testid="project-badge-animation"
  >
    <div
      className={cx(
        "project-badge-animation-a",
        {
          "bg-grass": type === PROJECT_BADGE_TYPES.primary,
        },
        {
          "bg-fire-700": type === PROJECT_BADGE_TYPES.error,
        }
      )}
      data-testid="project-badge-animation-a"
    />

    <div
      className={cx(
        "project-badge-animation-b",
        {
          "bg-grass": type === PROJECT_BADGE_TYPES.primary,
        },
        {
          "bg-fire-700": type === PROJECT_BADGE_TYPES.error,
        }
      )}
      data-testid="project-badge-animation-b"
    />
  </div>
)

export default ProjectBadgeAnimation
