import { ReactElement, useState } from "react"
import { useQueryClient } from "@tanstack/react-query"
import cx from "classnames"
import * as Yup from "yup"
import { Form, Formik, FormikProps, FormikValues } from "formik"

import { genericErrMsg } from "../api/auth"
import ErrorCard from "../components/ErrorCard"
import { SubmitButton } from "../components/SubmitButton"
import { WrappedInput, WrappedInputAsTypes } from "../components/Input"
import MultiSelect from "../components/MultiSelect"
import PreferredContactInput from "../components/PreferredContactInput"
import { ButtonPair } from "../components/ButtonPair"
import { useUpdateProfile } from "../hooks"
import { phoneRegex } from "../shared/constants"
import { Profile } from "@/types"
import { AxiosError } from "axios"

interface EmailFieldTypes {
  as: WrappedInputAsTypes
  label: string
  name: string
  placeholder: string
  readOnly: boolean
  type: string
}

interface FormComponentTypes {
  backLink: ReactElement<any, any>
  className?: string
  errorMessage: string | null
  formikProps: FormikProps<Profile>
  isEmailVerified: boolean
  showVerificationStatus: boolean
  submitText: string
}

interface ContactInfoFormTypes {
  initialValues: Profile
  onSuccess: () => void
  showVerificationStatus: boolean
  backLink: ReactElement
  submitText: string
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Please provide a valid email address"),
  first_name: Yup.string().required("Please provide first name"),
  last_name: Yup.string().required("Please provide last name"),
  phone_number: Yup.string()
    .matches(phoneRegex, "Please provide a valid phone number")
    .required("Please provide a phone number"),
  preferred_contact_method: Yup.array()
    .min(1, "Contact preference required")
    .of(Yup.string().required())
    .required("Please provide a preferred contact method"),
})

const EmailField = ({
  as,
  label,
  name,
  placeholder,
  readOnly,
  type,
}: EmailFieldTypes) => {
  return (
    <>
      <WrappedInput
        as={as}
        label={label}
        name={name}
        placeholder={placeholder}
        readOnly={readOnly}
        type={type}
      />
    </>
  )
}

const FormComponent = ({
  backLink,
  className,
  errorMessage,
  formikProps,
  submitText,
}: FormComponentTypes) => (
  <Form className={cx("max-w-xs  md:max-w-1.5xl", className)}>
    {errorMessage && <ErrorCard className="mb-6">{errorMessage}</ErrorCard>}
    <div className="mb-4 grid grid-cols-1 gap-4  md:grid-cols-2 md:gap-3">
      <div>
        <WrappedInput
          label="First name"
          placeholder="First name"
          type="text"
          name="first_name"
        />
      </div>

      <div>
        <WrappedInput
          label="Last name"
          placeholder="Last name"
          type="text"
          name="last_name"
        />
      </div>
    </div>

    <div className="mb-4 grid grid-cols-1 gap-4  md:grid-cols-2 md:gap-3">
      <div>
        <EmailField
          label="Email"
          placeholder="Email"
          type="text"
          name="email"
          readOnly={true}
          as="email"
        />
      </div>

      <div>
        <WrappedInput
          label="Phone number"
          placeholder="Phone number"
          type="text"
          name="phone_number"
        />
      </div>
    </div>

    <div className="mb-6 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-3">
      <div>
        <PreferredContactInput />
      </div>

      <div>
        <MultiSelect
          labelClass="label font-bold mb-2 leading-18px"
          aria-label="How did you hear about us?"
          label="How did you hear about us? (Optional)"
          name="referral_source"
          placeholder="Select how you heard about us"
          options={[
            {
              label: "Friend/family/colleague",
              value: "friend_family_colleague",
            },
            {
              label: "Forestry professional",
              value: "forestry_professional",
            },
            {
              label: "Event/webinar",
              value: "event_webinar",
            },
            {
              label: "Newspaper/magazine",
              value: "newspaper_magazine",
            },
            {
              label: "Social media/internet search",
              value: "social_media_internet_search",
            },
            {
              label: "Postcard/direct mail",
              value: "postcard_direct_mail",
            },
            {
              label: "Other",
              value: "other",
            },
          ]}
        />
      </div>
    </div>

    <ButtonPair
      gapClass="gap-2"
      // eslint-disable-next-line react/no-unstable-nested-components
      primary={(primaryProps) => (
        <SubmitButton
          isSubmitting={formikProps.isSubmitting}
          disabled={!formikProps.dirty}
          {...primaryProps}
        >
          {submitText}
        </SubmitButton>
      )}
      secondary={
        <backLink.type
          {...backLink.props}
          className="btn2 btn2-outline-primary font-semibold"
        />
      }
    />
  </Form>
)

// DEV: Minimize loading spinner for form by receiving `initialValues` from parent
// DEV: Use params to filter out props exclusive to this component (not FormComponent)
const ContactInfoForm = ({
  initialValues,
  onSuccess,
  showVerificationStatus,
  backLink,
  submitText,
}: ContactInfoFormTypes) => {
  const queryClient = useQueryClient()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const { mutateAsync: updateProfile } = useUpdateProfile(queryClient, {
    onSuccess,
    onError: (error: AxiosError) => {
      setErrorMessage(error?.message || genericErrMsg)
    },
  })

  const handleSubmit = async (values: FormikValues) => {
    setErrorMessage(null)
    await updateProfile(values)
  }

  return (
    <div className="grid grid-cols-1 gap-4 mb-10  md:mb-0 md:order-2 md:w-full md:grid-cols-3">
      <div className="md:order-1 md:col-span-full">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <FormComponent
              errorMessage={errorMessage}
              formikProps={formikProps}
              isEmailVerified={initialValues.is_email_verified}
              backLink={backLink}
              showVerificationStatus={showVerificationStatus}
              submitText={submitText}
            />
          )}
        </Formik>

        <div className="block mt-15">
          <a
            href="https://ncx.com/privacy"
            className="link font-bold"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          <span className="inline-block text-leaf font-bold mx-1">|</span>
          <a
            href="https://ncx.com/terms"
            className="link font-bold"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms and Conditions
          </a>
        </div>
      </div>
    </div>
  )
}

export default ContactInfoForm
