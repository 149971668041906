import { DialogStateReturn } from "reakit"

import ProjectMapOverlay from "./ProjectMapOverlay"
import { shortenAcreage } from "../../../utils"
import { LANDOWNER_STATUS } from "../../../shared/constants"
import { BadgeDisplayType, IneligibilityReasonsType } from "@/types/constants"

interface ProjectMapOverlayContainerProps {
  isIneligible: boolean
  ineligibilityReasons: IneligibilityReasonsType[] | null | undefined
  eligibleAcres: number
  totalAcreage: number | undefined
  isMember?: boolean
  badgeDisplay: BadgeDisplayType
  eligibilityDialog: DialogStateReturn
}

const ProjectMapOverlayContainer = ({
  isIneligible,
  ineligibilityReasons,
  eligibleAcres,
  totalAcreage,
  isMember,
  badgeDisplay,
  eligibilityDialog,
}: ProjectMapOverlayContainerProps) => {
  if (isIneligible) {
    return (
      <ProjectMapOverlay
        title={
          ineligibilityReasons && ineligibilityReasons.length > 0
            ? ineligibilityReasons[0].charAt(0).toUpperCase() +
              ineligibilityReasons[0].slice(1)
            : "You are ineligible"
        }
        text={`${
          typeof eligibleAcres === "number" ? shortenAcreage(eligibleAcres) : 0
        }/${shortenAcreage(totalAcreage || 0)} acres eligible for this program`}
      />
    )
  }

  if (!isIneligible && totalAcreage && totalAcreage >= 20000) {
    return (
      <ProjectMapOverlay
        title="Wow! You've got a lot of land"
        text="Let's talk about your eligibility."
        ctaUrl="mailto:landowners@ncx.com"
        ctaText="Get in Touch"
      />
    )
  }

  if (
    isMember !== false &&
    badgeDisplay === LANDOWNER_STATUS.information_needed
  ) {
    return (
      <ProjectMapOverlay
        title="Discover your eligibility"
        text="Answer questions about your land to unlock eligibility."
        ctaType="button"
        ctaText="Check Eligibility"
        ctaOnClick={eligibilityDialog.show}
      />
    )
  }

  return null
}

export default ProjectMapOverlayContainer
