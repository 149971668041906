import { useQueryClient } from "@tanstack/react-query"

import MainLayout from "./_layouts/Main"
import AskAQuestionClose from "../sections/AskAQuestion/AskAQuestionClose"
import AskAQuestionImage from "../sections/AskAQuestion/AskAQuestionImage"
import AskAQuestionHeader from "../sections/AskAQuestion/AskAQuestionHeader"
import AskAQuestionForm from "../sections/AskAQuestion/AskAQuestionForm"
import { useAccountId, useAccount, useProfile } from "../hooks"
import { Profile } from "@/types"
import { AccountTypes } from "@/types/account"

const AskAQuestion = () => {
  const accountId = useAccountId()
  const queryClient = useQueryClient()
  const { data: profile, isLoading: profileIsLoading } = useProfile<
    Profile,
    Error
  >(queryClient)

  const { data: account, isLoading: accountIsLoading } = useAccount<
    AccountTypes,
    Error
  >(queryClient, accountId)

  return (
    <MainLayout
      isLoading={[profileIsLoading, accountIsLoading]}
      contentBg="white"
      className="ask-a-question-page"
    >
      <div className="pb-24 sm:pb-0">
        <AskAQuestionClose />

        <div className="container xl:max-w-[1440px] flex justify-between gap-8">
          <AskAQuestionImage />

          <div className="md:w-[500px] max-w-full">
            <AskAQuestionHeader />

            <AskAQuestionForm account={account} profile={profile} />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default AskAQuestion
