import { NavLinksTypes } from "./types"

export const PARTNERS_TOPBAR_LINKS: NavLinksTypes[] = [
  {
    href: "/partners",
    exact: true,
    title: "Partners",
  },
]

export const PARTNERS_SLIDE_IN_LINKS: NavLinksTypes[] = [
  {
    href: "/partners",
    exact: true,
    title: "Partners",
  },
]

export const SINGLE_ACCOUNT_TOPBAR_LINKS: NavLinksTypes[] = [
  { href: "/", exact: true, title: "Dashboard" },
  {
    href: "/programs/page/1",
    exact: true,
    title: "Programs",
  },
  { href: "/threats", exact: true, title: "Threats" },
]

export const SINGLE_ACCOUNT_SLIDE_IN_LINKS: NavLinksTypes[] = [
  { href: "/", exact: true, title: "Dashboard" },
  {
    href: "/programs/page/1",
    exact: true,
    title: "Programs",
  },
  { href: "/threats", exact: true, title: "Threats" },
  { href: "/settings", exact: false, title: "Settings" },
]

export const MULTI_ACCOUNT_TOPBAR_LINKS = (accountId: string) => {
  return accountId === "_single"
    ? [{ href: "/accounts", exact: true, title: "Accounts" }]
    : [
        { href: `/accounts/${accountId}`, exact: true, title: "Dashboard" },
        {
          href: `/accounts/${accountId}/programs/page/1`,
          exact: true,
          title: "Programs",
        },
        {
          href: `/accounts/${accountId}/threats`,
          exact: true,
          title: "Threats",
        },
        { href: "/accounts", exact: true, title: "Accounts" },
      ]
}

export const MULTI_ACCOUNT_SLIDE_IN_LINKS = (accountId: string) => {
  return accountId === "_single"
    ? [{ href: "/accounts", exact: true, title: "Accounts" }]
    : [
        { href: `/accounts/${accountId}`, exact: true, title: "Dashboard" },
        {
          href: `/accounts/${accountId}/programs/page/1`,
          exact: true,
          title: "Programs",
        },
        {
          href: `/accounts/${accountId}/threats`,
          exact: true,
          title: "Threats",
        },
        { href: "/accounts", exact: true, title: "Accounts" },
        {
          href: `/accounts/${accountId}/settings/account-info`,
          exact: true,
          title: "Settings",
        },
      ]
}
