import { Button } from "reakit/Button"
import { TooltipReference, TooltipStateReturn } from "reakit/Tooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"

export interface TooltipTriggerTypes {
  className?: string
  tooltip: TooltipStateReturn
}

const TooltipTrigger = ({ className = "", tooltip }: TooltipTriggerTypes) => (
  <TooltipReference
    {...tooltip}
    as={Button}
    className={cx("text-base", className)}
  >
    <FontAwesomeIcon icon={faCircleInfo} title={faCircleInfo.iconName} />
  </TooltipReference>
)

export default TooltipTrigger
