import { Dispatch, SetStateAction } from "react"
import cx from "classnames"
import { Feature, FeatureCollection, Geometry, Properties } from "@turf/helpers"

import ProjectMapParcelsFeature from "./ProjectMapParcelsFeature"
import { getViewportFromFeature } from "./helpers"
import { ViewportTypes } from "@/types"
import ProjectMapParcelsListFeatureContent from "./ProjectMapParcelsListFeatureContent"

interface ProjectMapParcelsListProps {
  parcelData: FeatureCollection<Geometry, Properties> | null | undefined
  enableEligibility: boolean
  isIneligible: boolean
  setViewport: (newViewport: ViewportTypes | null) => void
  activeFeature: number | null
  setActiveFeature: Dispatch<SetStateAction<number | null>>
}

const ProjectMapParcelsList = ({
  parcelData,
  enableEligibility,
  isIneligible,
  setViewport,
  activeFeature,
  setActiveFeature,
}: ProjectMapParcelsListProps) => {
  const handleFeatureClick = (
    index: number,
    feature: Feature<Geometry, Properties>,
    disabled: boolean
  ) => {
    if (!disabled) {
      setActiveFeature(index)
      setViewport(getViewportFromFeature(feature))
    }
  }

  const handleFeatureKeyDown = (
    e: React.KeyboardEvent<HTMLLIElement>,
    index: number,
    feature: Feature<Geometry, Properties>,
    disabled: boolean
  ) => {
    if (e.key === "Enter") {
      handleFeatureClick(index, feature, disabled)
    }
  }

  return (
    <ul className="block lg:!h-auto xl:!h-[340px] border border-charcoal-50 border-t-0 xl:border-t-1 border-b-0 rounded-b xl:rounded-tr xl:rounded-br-none overflow-y-auto mt-0 mb-auto">
      {parcelData?.features.map((feature, index, arr) => {
        const disabled =
          enableEligibility && feature?.properties?.elig_acres === 0

        return (
          <li
            key={index}
            tabIndex={isIneligible ? -1 : 0}
            onClick={() => handleFeatureClick(index, feature, disabled)}
            onKeyDown={(e) => handleFeatureKeyDown(e, index, feature, disabled)}
            className={cx(
              "w-full flex justify-between items-center p-2 border-b-1 border-b-charcoal-50 focus:ring-inset",
              "text-charcoal-500 text-sm leading-130 tracking-0.14",
              { "bg-charcoal-50": disabled },
              { "hover:bg-grass-50 cursor-pointer": !disabled },
              { "bg-grass-50": activeFeature === index },
              { "border-b-0": index === arr.length - 1 }
            )}
          >
            <ProjectMapParcelsListFeatureContent
              enableEligibility={enableEligibility}
              eligAcres={feature?.properties?.elig_acres}
              parcelAcres={feature?.properties?.parcel_acres}
            />

            <ProjectMapParcelsFeature
              parcelData={parcelData}
              featureIndex={index}
            />
          </li>
        )
      })}
    </ul>
  )
}

export default ProjectMapParcelsList
