import cx from "classnames"
import MapMainMenuButton from "./MapMainMenuButton"

interface MapMainMenuTypes {
  show: boolean
  className?: string
  mode?: string
  isSelectVisible?: boolean
  isParcelSelectVisible?: boolean
  isDrawPolygonVisible?: boolean
  isFileUploadVisible?: boolean
  isEditVisible?: boolean
  isTrashVisible?: boolean
  isBackVisible?: boolean
  isClearVisible?: boolean
  onClickSelect?: () => void
  onClickParcelSelect?: () => void
  onClickDrawPolygon?: () => void
  onClickFileUpload?: () => void
  onClickEdit?: () => void
  onClickTrash?: () => void
  onClickBack?: () => void
  onClickClear?: () => void
}

export const MapMainMenu = ({
  show,
  className,
  mode,
  isSelectVisible,
  isParcelSelectVisible,
  isDrawPolygonVisible,
  isFileUploadVisible,
  isEditVisible,
  isTrashVisible,
  isBackVisible,
  isClearVisible,
  onClickSelect,
  onClickParcelSelect,
  onClickDrawPolygon,
  onClickFileUpload,
  onClickEdit,
  onClickTrash,
  onClickBack,
  onClickClear,
}: MapMainMenuTypes) => {
  if (!show) {
    return null
  }

  return (
    <div className={cx(className, "space-y-4")}>
      <div>
        {isSelectVisible && (
          <MapMainMenuButton
            isActive={mode === "simple_select"}
            onClick={onClickSelect}
            iconClassName="fas fa-mouse-pointer"
          >
            Select Shapes
          </MapMainMenuButton>
        )}

        {isParcelSelectVisible && (
          <MapMainMenuButton
            isActive={mode === "parcel_select"}
            onClick={onClickParcelSelect}
            iconClassName="far fa-hand-pointer"
          >
            Select Parcels
          </MapMainMenuButton>
        )}

        {isDrawPolygonVisible && (
          <MapMainMenuButton
            isActive={mode === "draw_polygon"}
            onClick={onClickDrawPolygon}
            iconClassName="fas fa-draw-polygon"
          >
            Draw Shapes
          </MapMainMenuButton>
        )}

        {isFileUploadVisible && (
          <MapMainMenuButton
            isActive={mode === "file_upload"}
            onClick={onClickFileUpload}
            iconClassName="fas fa-upload"
          >
            Upload File
          </MapMainMenuButton>
        )}

        {isEditVisible && (
          <MapMainMenuButton
            isActive={mode === "direct_select"}
            onClick={onClickEdit}
            iconClassName="far fa-edit"
          >
            Edit Shape
          </MapMainMenuButton>
        )}

        {isTrashVisible && (
          <MapMainMenuButton
            onClick={onClickTrash}
            iconClassName="fas fa-trash-alt"
          >
            Delete {mode === "direct_select" ? "Points" : "Shapes"}
          </MapMainMenuButton>
        )}
      </div>
      <div>
        {isBackVisible && (
          <MapMainMenuButton
            onClick={onClickBack}
            iconClassName="fas fa-arrow-left"
            className="-mt-4"
          >
            Back To Tools
          </MapMainMenuButton>
        )}
      </div>
      <div>
        {isClearVisible && (
          <MapMainMenuButton
            onClick={onClickClear}
            className="place-content-center"
          >
            Clear All Bounds
          </MapMainMenuButton>
        )}
      </div>
    </div>
  )
}
