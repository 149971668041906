export interface ProjectCardInnactiveBadgeProps {
  showMinimalInfo: boolean
  isActive: boolean | null | undefined
}

const ProjectCardInnactiveBadge = ({
  showMinimalInfo,
  isActive,
}: ProjectCardInnactiveBadgeProps) => {
  if (showMinimalInfo || isActive) {
    return null
  }

  return (
    <h3 className="text-[#f9303f] text-center font-semibold leading-[30px] uppercase absolute top-4 right-16">
      Inactive
    </h3>
  )
}

export default ProjectCardInnactiveBadge
