import MapGL, { NavigationControl } from "@urbica/react-map-gl"
// @ts-ignore
import Draw from "@urbica/react-map-gl-draw"
import "mapbox-gl/dist/mapbox-gl.css"
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css"
// @ts-ignore
import StaticMode from "@mapbox/mapbox-gl-draw-static-mode"
import { AddressAutocomplete } from "../AddressAutocomplete"
import { MapMainMenu } from "../MapMainMenu"
import { LayerPicker } from "../LayerPicker"
import { ParcelSelectLayer, ParcelSelectMode } from "../ParcelSelectLayer"
import mapVisualizationDrawStyles from "../../shared/mapVisualizationDrawStyles"
import { MAPBOX_TOKEN, mapStyles } from "../../shared/constants"
import { FileUploadMode } from "./helpers"
import { LayerType } from "@/types/mapbox"
import { MapVisualizationMapProps } from "./types"

const MapVisualizationMap = ({
  ref,
  mapRef,
  drawRef,
  layer,
  viewport,
  mode,
  onViewportChange,
  features,
  displayMapEditingTools,
  modeOptions,
  handleDrawSelectionChange,
  handleDrawDelete,
  handleDrawCreate,
  handleDrawUpdate,
  handleDrawModeChange,
  showMenuSelect,
  showMenuParcelSelect,
  showMenuDrawPolygon,
  showMenuEdit,
  showMenuTrash,
  showMenuBack,
  showMenuClear,
  handleMenuParcelSelect,
  handleMenuDrawPolygon,
  handleMenuEdit,
  handleMenuTrash,
  handleMenuBack,
  handleMenuFileUpload,
  onFeatureClear,
  showMenuFileUpload,
  handleMenuSelect,
  search,
  setSearch,
  handleSearchSelect,
  setLayer,
  setMode,
}: MapVisualizationMapProps) => {
  const handleLayerChange = (newLayer: LayerType) => {
    setLayer(newLayer)

    if (mode === "direct_select") {
      setMode("reset")
    }
  }

  return (
    <div
      ref={ref}
      tabIndex={0}
      className="map-vizualization relative flex-auto flex flex-col"
      aria-label="Map"
    >
      <MapGL
        ref={mapRef}
        pitchWithRotate={false}
        dragRotate={false}
        className="flex-auto"
        mapStyle={mapStyles[layer].url}
        accessToken={MAPBOX_TOKEN}
        onViewportChange={onViewportChange}
        latitude={viewport.latitude}
        longitude={viewport.longitude}
        zoom={viewport.zoom}
        showTileBoundaries={false}
        maxZoom={17}
      >
        <ParcelSelectLayer
          mapRef={mapRef}
          layer={layer}
          features={features}
          mode={mode}
        />

        {/* DEV: Draw must always be rendered to properly load `ParcelSelectMode` */}
        <Draw
          ref={drawRef}
          data={{
            type: "FeatureCollection",
            features: mode === "parcel_select" ? [] : features,
          }}
          styles={mapVisualizationDrawStyles}
          mode={!displayMapEditingTools ? "static" : mode}
          modeOptions={modeOptions}
          modes={(defaultModes: { [x: string]: any }) => ({
            ...defaultModes,
            parcel_select: ParcelSelectMode,
            file_upload: FileUploadMode,
            reset: defaultModes["simple_select"],
            static: StaticMode,
          })}
          lineStringControl={false}
          polygonControl={false}
          pointControl={false}
          combineFeaturesControl={false}
          uncombineFeaturesControl={false}
          trashControl={false}
          onDrawSelectionChange={handleDrawSelectionChange}
          onDrawDelete={handleDrawDelete}
          onDrawCreate={handleDrawCreate}
          onDrawUpdate={handleDrawUpdate}
          onDrawModeChange={handleDrawModeChange}
        />

        <NavigationControl showCompass={false} showZoom position="top-right" />
      </MapGL>

      <MapMainMenu
        show={displayMapEditingTools}
        className="absolute top-20 left-4 md:top-4 lg:top-12 lg:left-12"
        mode={mode}
        isSelectVisible={showMenuSelect()}
        isParcelSelectVisible={showMenuParcelSelect()}
        isDrawPolygonVisible={showMenuDrawPolygon()}
        isFileUploadVisible={showMenuFileUpload()}
        isEditVisible={showMenuEdit()}
        isTrashVisible={showMenuTrash()}
        isBackVisible={showMenuBack()}
        isClearVisible={showMenuClear()}
        onClickSelect={handleMenuSelect}
        onClickParcelSelect={handleMenuParcelSelect}
        onClickDrawPolygon={handleMenuDrawPolygon}
        onClickFileUpload={handleMenuFileUpload}
        onClickEdit={handleMenuEdit}
        onClickTrash={handleMenuTrash}
        onClickBack={handleMenuBack}
        onClickClear={onFeatureClear}
      />

      <AddressAutocomplete
        className="bg-white rounded"
        value={search}
        onChange={setSearch}
        onSelect={(address) => {
          handleSearchSelect(address)
        }}
        onError={() => {}}
      />

      <LayerPicker
        layer={layer}
        onLayerChange={handleLayerChange}
        className="md:top-28 md:right-4 lg:top-36 lg:right-12 hidden md:block"
      />
    </div>
  )
}

export default MapVisualizationMap
