import { ReactNode, useMemo, useState, useEffect } from "react"

import MapGL, { Source, Layer } from "@urbica/react-map-gl"
import { bbox } from "@turf/turf"
import buffer from "@turf/buffer"

import EligibilityLayer, {
  ToggleEligibilityLayer,
} from "../../../components/EligibilityLayer"
import { MAPBOX_TOKEN, mapStyles } from "../../../shared/constants"
import { useAccessToken } from "../../../stores"
import { heapTrackEvent } from "../../../api/integrations"
import { TileURLsTypes } from "@/types/tiles"
import { ViewportTypes } from "@/types"
import { FeatureCollection, Geometry, Properties } from "@turf/helpers"

interface ProjectMapVisualizationTypes {
  viewport: ViewportTypes | null
  setViewport: (newViewport: ViewportTypes | null) => void
  parcelData: FeatureCollection<Geometry, Properties> | undefined
  tileData?: TileURLsTypes
  activeFeature: number | null
  isIneligible: boolean
  enableEligibility?: boolean
  enableZoomPan?: boolean
  layerSwitchText?: string
  legend?: ReactNode
  overlay?: ReactNode
  isMember?: boolean
  heapEvent?: string
}

const ProjectMapVisualization = ({
  viewport,
  setViewport,
  parcelData,
  tileData,
  activeFeature,
  isIneligible,
  enableEligibility = true,
  enableZoomPan = false,
  layerSwitchText,
  legend,
  overlay,
  isMember,
  heapEvent,
}: ProjectMapVisualizationTypes) => {
  const [showEligibilityLayer, setShowEligibilityLayer] =
    useState<boolean>(enableEligibility)
  const accessToken = useAccessToken()

  useEffect(() => {
    setShowEligibilityLayer(enableEligibility)
  }, [enableEligibility])

  // DEV: Get bounding box around buffered area. 80.4672km = 50 miles
  const bounds = useMemo(() => {
    if (!parcelData) return null

    return bbox(
      // @ts-ignore
      buffer(parcelData, 80.4672, {
        units: "kilometers",
      })
    )
  }, [parcelData])

  const handleViewportChange = (newViewport: ViewportTypes) => {
    if (heapEvent?.length) {
      heapTrackEvent(heapEvent, {
        zoom: newViewport.zoom,
        latitude: newViewport.latitude,
        longitude: newViewport.longitude,
      })
    }

    setViewport(newViewport)
  }

  if (viewport === null || viewport === undefined) {
    return null
  }

  return (
    <div tabIndex={isIneligible ? -1 : 0} className="w-full h-[400px] relative">
      <MapGL
        style={{ width: "100%", height: "400px" }}
        mapStyle={mapStyles.aerial.url}
        accessToken={MAPBOX_TOKEN}
        onViewportChange={handleViewportChange}
        viewportChangeMethod="flyTo"
        viewportChangeOptions={{
          duration: 1000,
        }}
        cursorStyle="default"
        scrollZoom={enableZoomPan}
        doubleClickZoom={enableZoomPan}
        dragPan={enableZoomPan}
        attributionControl={false}
        maxBounds={
          bounds
            ? [
                [bounds[0], bounds[1]],
                [bounds[2], bounds[3]],
              ]
            : undefined
        }
        transformRequest={(url) => {
          if (
            url.includes("tipg") ||
            url.includes("amazonaws.com/collections") ||
            url.includes("amazonaws.com/searches")
          ) {
            return {
              url: url,
              headers: { Authorization: `Bearer ${accessToken}` },
              credentials: "include",
            }
          }
          return { url }
        }}
        {...viewport}
      >
        <Source
          id="features"
          type="geojson"
          data={
            activeFeature !== null
              ? {
                  type: "FeatureCollection",
                  features: parcelData?.features,
                }
              : null
          }
        />

        <Layer
          id="featuresLayer"
          source="features"
          type="line"
          paint={{
            "line-color": "#77A0C3",
            "line-width": 2,
          }}
        />

        <EligibilityLayer
          showEligibilityLayer={showEligibilityLayer && isMember !== false}
          tileData={tileData}
        />

        {legend}

        {overlay}
      </MapGL>

      <ToggleEligibilityLayer
        show={enableEligibility && !!layerSwitchText}
        value={showEligibilityLayer}
        label={
          <>
            Layer: <span className="font-normal">{layerSwitchText}</span>
          </>
        }
        onChange={() => setShowEligibilityLayer((oldValue) => !oldValue)}
      />
    </div>
  )
}

export default ProjectMapVisualization
