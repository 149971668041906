interface ScoreCardDialogActionsGuideURLProps {
  guideURL: string | null
  title: string
}

const ScoreCardDialogActionsGuideURL = ({
  guideURL,
  title,
}: ScoreCardDialogActionsGuideURLProps) => {
  if (!guideURL) {
    return null
  }

  return (
    <p className="text-charcoal-500 text-sm font-body font-bold leading-[140%] tracking-[0.42px]">
      Learn More:{" "}
      <a href={guideURL} className="text-leaf" target="_blank" rel="noreferrer">
        {title} guide - NCX
      </a>
    </p>
  )
}

export default ScoreCardDialogActionsGuideURL
