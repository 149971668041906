import cx from "classnames"

export interface NumberedListItemTypes {
  heading: string
  text: string
  className: string
}

const NumberedListItem = ({
  heading,
  text,
  className = "",
}: NumberedListItemTypes) => (
  <li
    className={cx(
      "relative pl-9",
      "flex flex-col gap-2",
      "before:w-6 before:h-6 before:absolute before:left-0 before:flex before:items-center before:justify-center before:bg-sand before:rounded-full",
      "before:text-charcoal-500 before:text-sm before:font-overline before:font-medium",
      className
    )}
  >
    <h3 className="text-base lg:text-lg leading-130 tracking-0.32 lg:tracking-0.378">
      {heading}
    </h3>

    <p className="text-base leading-130 tracking-0.32">{text}</p>
  </li>
)

export default NumberedListItem
