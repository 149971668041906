import { Link } from "react-router"
import cx from "classnames"

import { useAccountUrlPrefix } from "../../hooks"
import { eligibilityQuizBackground } from "../EligibilityQuiz/helpers"
import { PROJECT_TYPES } from "../../shared/constants"
import { getProjectEarningsNullValue } from "../../shared/utils"
import { formatCurrency } from "../../utils"
import {
  EligibilityQuizCategoryType,
  ProgramCategoryType,
} from "../../types/constants"

export interface LandProfileTypes {
  quizCategory: EligibilityQuizCategoryType
  completed: boolean
  projectType: ProgramCategoryType
  potentialEarnings?: number | null
  numEligiblePrograms?: number | null
  className?: string
}

const LandProfile = ({
  quizCategory,
  completed,
  projectType,
  potentialEarnings,
  numEligiblePrograms,
  className,
}: LandProfileTypes) => {
  const accountUrlPrefix = useAccountUrlPrefix()
  const bgImage = eligibilityQuizBackground(quizCategory)

  return (
    <Link
      className={cx("quiz-card cursor-pointer px-6 py-3 rounded", className)}
      style={{
        background: `linear-gradient(0deg, rgba(36, 36, 38, 0.70) 0%, rgba(36, 36, 38, 0.70) 100%), url(${bgImage}) lightgray 50% / cover no-repeat`,
        backgroundPosition:
          quizCategory === "regen_ag"
            ? "50% 40%"
            : quizCategory === "general"
              ? "50% 8%"
              : "50%",
      }}
      to={
        completed
          ? `${accountUrlPrefix}/programs/page/1?category=${projectType}`
          : `${accountUrlPrefix}/eligibility-quiz/${quizCategory}`
      }
      aria-label={PROJECT_TYPES[projectType]?.long}
      id={quizCategory}
    >
      {numEligiblePrograms !== null && numEligiblePrograms !== undefined && (
        <div className="text-white text-[10px] text-overline italic uppercase font-medium leading-[15px] tracking-wide">
          {`${numEligiblePrograms} eligible ${
            numEligiblePrograms === 1 ? "program" : "programs"
          }`}
        </div>
      )}

      <div className="w-full flex justify-between">
        <h3 className="text-white text-base leading-[140%] tracking-0.32">
          {PROJECT_TYPES[projectType]?.long}
        </h3>

        <p className="text-white text-base leading-[140%] tracking-0.32">
          {potentialEarnings === null || potentialEarnings === undefined
            ? getProjectEarningsNullValue(projectType)
            : `${formatCurrency(potentialEarnings)}/acre`}
        </p>
      </div>

      <p className="text-white text-left text-xs leading-[140%] tracking-0.36 mt-1">
        {PROJECT_TYPES[projectType]?.description}
      </p>
    </Link>
  )
}

export default LandProfile
