import { DialogStateReturn } from "reakit"

import ScoreCardDisclosure from "./ScoreCardDisclosure"
import ScoreCardItem from "./ScoreCardItem"
import { ScoreData } from "@/types/property"

interface ScoreCardItemsProps {
  dialog: DialogStateReturn
  scoreData: ScoreData | null
  handleActiveScoreCardItem: (item: string) => void
}

const ScoreCardItems = ({
  dialog,
  scoreData,
  handleActiveScoreCardItem,
}: ScoreCardItemsProps) => (
  <div className="grid xxl:grid-cols-2 gap-4">
    <ScoreCardDisclosure
      dialog={dialog}
      onClick={() => handleActiveScoreCardItem("carbon_potential")}
      ariaLabel="Forest Carbon"
    >
      <ScoreCardItem
        currentScore={scoreData?.carbon_potential.current_score ?? 0}
        maxScore={scoreData?.carbon_potential.max_score ?? 0}
        category="carbon_potential"
      />
    </ScoreCardDisclosure>

    <ScoreCardDisclosure
      dialog={dialog}
      onClick={() => handleActiveScoreCardItem("forest_health")}
      ariaLabel="Forest Health"
    >
      <ScoreCardItem
        currentScore={scoreData?.forest_health.current_score ?? 0}
        maxScore={scoreData?.forest_health.max_score ?? 0}
        category="forest_health"
      />
    </ScoreCardDisclosure>

    <ScoreCardDisclosure
      dialog={dialog}
      onClick={() => handleActiveScoreCardItem("timber_volume")}
      ariaLabel="Timber"
    >
      <ScoreCardItem
        currentScore={scoreData?.timber_volume.current_score ?? 0}
        maxScore={scoreData?.timber_volume.max_score ?? 0}
        category="timber_volume"
      />
    </ScoreCardDisclosure>
  </div>
)

export default ScoreCardItems
