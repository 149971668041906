import { Dispatch, SetStateAction, useCallback, useState } from "react"
import { Link, useLocation } from "react-router"
import { Form, FormikProps } from "formik"

import ContentCard from "../../components/ContentCard"
import ErrorCard from "../../components/ErrorCard"
import { SubmitButton } from "../../components/SubmitButton"
import { WrappedInput } from "../../components/Input"
import { AUTH_STEPS } from "./constants"
import { AuthDataTypes } from "../../types/auth"

interface EmailLoginFormComponentTypes extends FormikProps<AuthDataTypes> {
  setAuthData: Dispatch<SetStateAction<AuthDataTypes>>
  email: string
  errorMessage: string | null
  isSubmitting: boolean
}

const EmailLoginFormComponent = ({
  setAuthData,
  email,
  values,
  errorMessage,
  isSubmitting,
}: EmailLoginFormComponentTypes) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const location = useLocation()

  const handleBlur = useCallback(() => {
    setAuthData({
      email,
      password: values.password,
    })
  }, [setAuthData, email, values.password])

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <ContentCard>
      <h3 className="text-lg text-center mb-2">
        <span>Welcome Back</span>
      </h3>
      <p className="text-base text-center leading-5 my-2">
        Log in to explore programs
      </p>
      <Form className="space-y-4" onBlur={handleBlur}>
        {errorMessage && <ErrorCard>{errorMessage}</ErrorCard>}
        <div className="space-y-4">
          <div>
            {/* DEV: If spacing was on outer div, then it'd overspace Link from error message*/}
            <div className="space-y-1">
              <WrappedInput
                data-test="input-password"
                label="Password"
                name="password"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                as="password"
                toggleShowPassword={toggleShowPassword}
              />
            </div>
            <div>
              <Link
                className="text-sm text-leaf link--underline-only"
                to={location.pathname}
                state={{
                  ...location.state,
                  authStep: AUTH_STEPS.resetPassword,
                }}
              >
                Forgot your password?
              </Link>
            </div>
          </div>

          <SubmitButton
            className="btn2 btn2-primary btn2-block font-semibold"
            isSubmitting={isSubmitting}
            data-test="email-log-in"
          >
            Log In
          </SubmitButton>
        </div>
        <div className="text-center">
          <a
            className="link--underline-only text-base text-dusk-500"
            href="mailto:landowners@ncx.com"
            data-test="having-trouble"
          >
            Having trouble logging in? Email us at{" "}
            <span className="link">landowners@ncx.com</span>
          </a>
        </div>
      </Form>
    </ContentCard>
  )
}

export default EmailLoginFormComponent
