import { ReactNode } from "react"
import { Link } from "react-router"
import cx from "classnames"

export interface PaginationLinkProps {
  children: ReactNode
  to: string | undefined
  className: string
}

const PaginationLink = ({ children, to, className }: PaginationLinkProps) => {
  if (to === undefined) {
    return (
      <div className={cx("pagination-link-disabled", className)}>
        {children}
      </div>
    )
  }

  return (
    <Link to={to} className={cx("pagination-link", className)}>
      {children}
    </Link>
  )
}

export default PaginationLink
