import timber_volume from "../../../images/icon-timber.svg"
import forest_health from "../../../images/icon-forest-health.svg"
import carbon_potential from "../../../images/icon-forest-carbon.svg"
import { SCORE_CARDS } from "./constants"

interface ScoreCardItemIconProps {
  icon: keyof typeof SCORE_CARDS
}

const ScoreCardItemIcon = ({ icon }: ScoreCardItemIconProps) => {
  const images: Record<keyof typeof SCORE_CARDS, keyof typeof SCORE_CARDS> = {
    timber_volume,
    forest_health,
    carbon_potential,
  }

  return <img src={images[icon]} alt={icon} className="block h-6 w-auto mr-2" />
}

export default ScoreCardItemIcon
