import { ReactNode } from "react"
import { Field } from "formik"
import cx from "classnames"

export interface Checkbox2Props {
  id?: string
  name: string
  label?: ReactNode
  disabled?: boolean
  className?: string
  "aria-label"?: string
  tabIndex?: number
}

export const Checkbox2 = ({
  id,
  name,
  label,
  disabled,
  className,
  "aria-label": ariaLabel,
  tabIndex,
}: Checkbox2Props) => {
  id = id || name

  return (
    <label
      htmlFor={id}
      className={cx(
        "flex flex-row cursor-pointer",
        className ? className : "mb-2",
        { "checkbox-label-disabled": disabled }
      )}
    >
      <span className="flex mr-2 shrink-0 items-center relative">
        <Field
          id={id}
          type="checkbox"
          name={name}
          className="checkbox"
          disabled={disabled}
          aria-label={ariaLabel}
          tabIndex={tabIndex}
        />
        <span className="checkmark"></span>
      </span>

      <span>{label}</span>
    </label>
  )
}
