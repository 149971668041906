import cx from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-solid-svg-icons"

import NumericPaginationPageLink from "./NumericPaginationPageLink"
import { usePagination, DOTS } from "../../hooks/usePagination"

export interface NumericPaginationTypes {
  show?: boolean
  totalCount: number
  siblingCount?: number
  currentPage: number
  pageSize: number
  urlRoot: string
  queryParams?: boolean
  className: string
}

const NumericPagination = ({
  show = true,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  urlRoot,
  queryParams,
  className,
}: NumericPaginationTypes) => {
  const paginationRange: (string | number)[] =
    usePagination({
      currentPage,
      totalCount,
      siblingCount,
      pageSize,
    }) || []

  if (!show || currentPage === 0 || paginationRange.length < 2) {
    return null
  }

  const lastPage = paginationRange[paginationRange.length - 1]

  return (
    <div className={cx("flex items-center justify-between", className)}>
      <div className="flex flex-1 items-center justify-between">
        <div>
          <nav className="flex flex-wrap gap-2" aria-label="Pagination">
            <NumericPaginationPageLink
              page={currentPage - 1}
              linkType={currentPage === 1 ? "disabled" : "default"}
              className="text-dusk-500 hover:text-grass-900"
              urlRoot={urlRoot}
              ariaLabel="Go to previous page"
              queryParams={queryParams}
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                className="h-5 w-5"
                aria-hidden="true"
              />
            </NumericPaginationPageLink>

            {paginationRange.map((pageNumber, index) => (
              <NumericPaginationPageLink
                key={pageNumber === DOTS ? `${DOTS}-${index}` : pageNumber}
                page={pageNumber}
                linkType={
                  pageNumber === DOTS
                    ? "dots"
                    : currentPage === pageNumber
                      ? "current"
                      : "default"
                }
                className="text-charcoal-500 hover:text-grass-900"
                urlRoot={urlRoot}
                queryParams={queryParams}
              >
                {pageNumber === DOTS ? <>&#8230;</> : pageNumber}
              </NumericPaginationPageLink>
            ))}

            <NumericPaginationPageLink
              page={currentPage + 1}
              linkType={currentPage === lastPage ? "disabled" : "default"}
              className="text-dusk-500 hover:text-grass-900"
              urlRoot={urlRoot}
              ariaLabel="Go to next page"
              queryParams={queryParams}
            >
              <FontAwesomeIcon
                icon={faChevronRight}
                className="h-5 w-5"
                aria-hidden="true"
              />
            </NumericPaginationPageLink>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default NumericPagination
