import { MouseEventHandler } from "react"
import cx from "classnames"

export interface CloseButtonProps {
  className?: string
  white?: boolean
  "aria-label"?: string
  onClick: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
}

export const CloseButton = ({
  className,
  white = false,
  "aria-label": ariaLabel,
  onClick,
  disabled = false,
}: CloseButtonProps) => (
  <button
    className={cx(
      "text-3xl w-6 h-6 leading-6 align-middle",
      white ? "text-white" : "text-dusk",
      className
    )}
    type="button"
    aria-label={ariaLabel}
    onClick={onClick}
    disabled={disabled}
  >
    &times;
  </button>
)
