import { useMemo } from "react"
import DOMPurify from "dompurify"
import { Formik } from "formik"
import { useNavigate } from "react-router"
import { useQueryClient } from "@tanstack/react-query"

import { Toast } from "../../components/Toast"
import AskAQuestionFormComponent from "./AskAQuestionFormComponent"
import {
  phoneRegex,
  PREFERRED_CONTACT_METHOD,
  PHONE_NUMBER,
} from "../../shared/constants"
import { genericErrMsg } from "../../api/auth"
import {
  useUpdateProfile,
  useCreateAsanaTask,
  useAccountUrlPrefix,
} from "../../hooks"
import { needsPhoneValidation, QUESTION, validationSchema } from "./helpers"
import { AsanaTaskCreationParams } from "@/types/asana"
import {
  AskAQuestionFormTypes,
  FormValuesTypes,
  NewContactValuesTypes,
} from "./types"

const AskAQuestionForm = ({ account, profile }: AskAQuestionFormTypes) => {
  const accountUrlPrefix = useAccountUrlPrefix()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { mutateAsync: updateProfile, status: updateProfileStatus } =
    useUpdateProfile(queryClient)
  const { mutateAsync: createAsanaTask, status: createAsanaTaskStatus } =
    useCreateAsanaTask(queryClient, {})

  const isSubmitting =
    updateProfileStatus === "pending" || createAsanaTaskStatus === "pending"

  const isValidPhoneNumber = useMemo(
    () => phoneRegex.test(profile?.phone_number || ""),
    [profile?.phone_number]
  )

  const initialValues: FormValuesTypes = {
    [PREFERRED_CONTACT_METHOD]: profile?.preferred_contact_method || [],
    [PHONE_NUMBER]: profile?.phone_number || "",
    [QUESTION]: "",
  }

  const handleCreateAsanaTask = async (
    question: string,
    newContactValues: NewContactValuesTypes
  ) => {
    const env = import.meta.env.VITE_APP_SENTRY_ENV || import.meta.env.MODE
    // PROD ? "Ask a question pipeline"; else: "Ask a question"
    const project =
      env === "production" ? "1208353947761341" : "1208254240698105"
    const taskName = `${env !== "production" ? "TEST: " : ""} ${question}`
    const contactMethods =
      newContactValues?.preferred_contact_method.join(", ") || ""

    const description =
      `<body><b>Question</b>: ${taskName}` +
      `\n<b>isMember</b>: ${account?.is_member}` +
      `\n<b>First Name</b>: ${profile?.first_name}` +
      `\n<b>Last Name</b>: ${profile?.last_name}` +
      `\n<a href='https://bark-api.ncx.com/admin/ncapx_platform/profile/${profile?.id}'>Profile</a>` +
      `\n<a href='https://bark-api.ncx.com/admin/ncapx_platform/account/${account?.id}'>Account</a>` +
      `\n<b>Preferred Contact method(s)</b>: ${contactMethods}` +
      `\n${
        newContactValues?.phone_number || profile?.phone_number
          ? `<b>Phone Number</b>: ${
              newContactValues?.phone_number || profile?.phone_number
            }`
          : ""
      }` +
      `\n${profile?.email ? `<b>Email</b>: ${profile?.email}` : ""}</body>`

    const taskParams: AsanaTaskCreationParams = {
      taskName,
      projects: [project],
      description,
      useHtmlDescription: true,
    }

    await createAsanaTask(taskParams, {
      onSuccess: () => {
        Toast.success("Question submitted successfully!")
        navigate(`${accountUrlPrefix}/`)
      },
      onError: () => {
        Toast.error(genericErrMsg)
        navigate(`${accountUrlPrefix}/`)
      },
    })
  }

  const handleFormSubmit = async (values: FormValuesTypes) => {
    const newContactValues: NewContactValuesTypes = {
      preferred_contact_method: values.preferred_contact_method,
      ...(needsPhoneValidation(values.preferred_contact_method)
        ? { phone_number: values.phone_number }
        : {}),
    }

    const sanitizedQuestion = DOMPurify.sanitize(values.question)

    await updateProfile(newContactValues, {
      onSuccess: () => {
        handleCreateAsanaTask(sanitizedQuestion, newContactValues)
      },
      onError: () => {
        Toast.error(genericErrMsg)
        navigate(`${accountUrlPrefix}/`)
      },
    })
  }

  return (
    <Formik<FormValuesTypes>
      initialValues={initialValues}
      validationSchema={() => validationSchema(isValidPhoneNumber)}
      validateOnBlur={false}
      validateOnChange={true}
      onSubmit={handleFormSubmit}
    >
      {(formikProps) => (
        <AskAQuestionFormComponent
          formikProps={formikProps}
          account={account}
          isSubmitting={isSubmitting}
        />
      )}
    </Formik>
  )
}

export default AskAQuestionForm
