import { useQueryClient } from "@tanstack/react-query"

import SettingsLayout from "../_layouts/Settings"
import SubscriptionManagementLink from "../../sections/SubscriptionManagement/SubscriptionManagementLink"
import AccountStatus from "../../sections/SubscriptionManagement/AccountStatus"
import { useAccount, useAccountId } from "../../hooks"
import { AccountTypes } from "../../types/account"

const SubscriptionManagement = () => {
  const accountId = useAccountId()
  const queryClient = useQueryClient()

  const { data: account, isLoading: accountIsLoading } = useAccount<
    AccountTypes,
    Error
  >(queryClient, accountId)

  return (
    <SettingsLayout
      isLoading={[accountIsLoading]}
      title="Subscription Management"
      subtitle="NCX manages your membership payments through our payment partner Stripe."
    >
      <AccountStatus
        isMember={account?.is_member}
        memberSince={account?.member_since_date}
      />

      <SubscriptionManagementLink isMember={account?.is_member} />
    </SettingsLayout>
  )
}

export default SubscriptionManagement
