import cx from "classnames"

import { ProjectVerificationBadgeTypes } from "./types"

const ProjectVerificationBadgeContentLabel = ({
  isVerified,
  includeLabel,
}: ProjectVerificationBadgeTypes) => {
  if (!includeLabel) {
    return null
  }

  const text = isVerified ? "Verified" : "Unverified"
  const className = isVerified
    ? "text-leaf font-semibold"
    : "text-dusk-700 font-normal"

  return <span className={cx("verified-text", className)}>{text}</span>
}

export default ProjectVerificationBadgeContentLabel
