import { RefObject, useRef } from "react"

import { Dialog, DialogBackdrop, DialogStateReturn } from "reakit"

interface LandMapOverlayDialogProps {
  dialog: DialogStateReturn
  visible: boolean
  dataNotReady: boolean
  setVisible: () => void
}

const LandMapOverlayDialog = ({
  dialog,
  visible,
  dataNotReady,
  setVisible,
}: LandMapOverlayDialogProps) => {
  const overlayRef = useRef<HTMLDivElement>(null)

  if (!dataNotReady) {
    return null
  }

  return (
    <div
      ref={overlayRef}
      className="relative w-full h-full z-[2] pointer-events-none"
    >
      <DialogBackdrop
        {...dialog}
        visible={visible}
        className="flex justify-center items-center w-full h-full bg-dusk-500/75 pointer-events-auto"
      >
        <Dialog
          {...dialog}
          visible={visible}
          aria-label="Data is pending"
          className="st-dialog opacity-100 w-full max-w-[397px]"
          style={{
            position: "static",
            transform: "none",
            zIndex: 2,
          }}
          unstable_finalFocusRef={overlayRef as RefObject<HTMLElement>}
        >
          <h4 className="text-charcoal-500 text-lg font-body font-bold leading-[140%] tracking-0.36">
            Data is pending
          </h4>

          <p className="text-charcoal-500 text-base font-body leading-[140%] tracking-0.32 mt-4">
            We are working on our analysis of your land cover data
          </p>

          <button
            className="btn2 btn2-primary text-base font-body mt-3"
            onClick={setVisible}
          >
            Dismiss
          </button>
        </Dialog>
      </DialogBackdrop>
    </div>
  )
}

export default LandMapOverlayDialog
