import { Dispatch, SetStateAction, useCallback, useState } from "react"
import cx from "classnames"
import { Link } from "react-router"
import { Form, FormikProps } from "formik"

import ContentCard from "../../components/ContentCard"
import ErrorCard from "../../components/ErrorCard"
import MultiSelect from "../../components/MultiSelect"
import { SsoAuthButtons, ContinueSeparator } from "./SsoAuthButtons"
import { SubmitButton } from "../../components/SubmitButton"
import { PopoverContentTypes, WrappedInput } from "../../components/Input"
import SignUpFooter from "./SignUpFooter"
import { AuthDataTypes, SignUpDataTypes } from "../../types/auth"
import { useShowProfileType } from "../../hooks/useShowProfileType"

interface SignUpFormComponentTypes extends FormikProps<SignUpDataTypes> {
  errorMessage: string | null
  inputEmail: string
  setAuthData: Dispatch<SetStateAction<AuthDataTypes | SignUpDataTypes>>
  onSsoSuccess: (values: any) => void
  onSocialError: () => void
}

const PROFILE_TYPES = [
  {
    value: "single_account",
    label: "One Account",
    popoverContent: ({ selected }: PopoverContentTypes) => (
      <div className="flex flex-row group">
        <i
          className={cx(
            "fas fa-user w-5 h-5 text-gray2 group-hover:text-moss",
            selected && "text-moss"
          )}
        />

        <div className="ml-4">
          <div className="font-semibold mb-1">One Account</div>
          <div className="text-sm">
            I manage property within a single business entity.
          </div>
          <div className="mt-1 text-sm text-dusk">
            e.g. Individual landowners
          </div>
        </div>
      </div>
    ),
  },
  {
    value: "multi_account",
    label: "Multiple Accounts",
    popoverContent: ({ selected }: PopoverContentTypes) => (
      <div className="flex flex-row group">
        <i
          className={cx(
            "fas fa-user-friends w-5 h-5 text-gray2 group-hover:text-moss",
            selected && "text-moss"
          )}
        />

        <div className="ml-4">
          <div className="font-semibold mb-1">Multiple Accounts</div>
          <div className="text-sm">
            I manage more than one property under multiple business entities.
          </div>
          <div className="mt-1 text-sm text-dusk">
            e.g. Consulting Foresters,{" "}
            <abbr
              title="Timber Investment Management Organizations"
              className="text-decoration-moss"
            >
              TIMOs
            </abbr>
          </div>
        </div>
      </div>
    ),
  },
]

const SignUpFormComponent = ({
  values, // form values
  isSubmitting,
  errorMessage,
  inputEmail,
  setAuthData,
  onSsoSuccess,
  onSocialError,
}: SignUpFormComponentTypes) => {
  const [showPassword, setShowPassword] = useState(false)
  const showProfileType = useShowProfileType()

  const handleBlur = useCallback(() => {
    setAuthData({
      email: values.email,
      password: values.password,
    })
  }, [setAuthData, values.email, values.password])

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <ContentCard>
      <h3 className="text-center text-lg mb-2">Welcome to NCX</h3>
      <p className="text-center text-base leading-130 tracking-0.32 mb-4">
        Finish signing up to explore programs
      </p>
      <Form className="space-y-4" onBlur={handleBlur}>
        {errorMessage && <ErrorCard>{errorMessage}</ErrorCard>}
        <div className="space-y-4">
          {showProfileType ? (
            <div className="space-y-1">
              <WrappedInput
                aria-label="Profile type"
                as="combobox"
                data-test="input-account-type"
                label="How many accounts will you manage?"
                name="profile_type"
                options={PROFILE_TYPES}
              />
            </div>
          ) : (
            <>
              <SsoAuthButtons
                onSuccess={onSsoSuccess}
                onError={onSocialError}
              />
              <ContinueSeparator />
            </>
          )}
          <div>
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-1">
                <WrappedInput
                  data-test="input-first-name"
                  label="First name"
                  name="first_name"
                  placeholder="First name"
                  type="text"
                />
              </div>
              <div className="space-y-1">
                <WrappedInput
                  data-test="input-last-name"
                  label="Last name"
                  name="last_name"
                  placeholder="Last name"
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="space-y-1">
            <WrappedInput
              data-test="input-email"
              label="Email address"
              name="email"
              placeholder="name@example.com"
              type="text"
              as="email"
            />
            {inputEmail && inputEmail !== values.email && (
              <div className="text-sm leading-5 text-dusk">
                Changing your email will lose the invite
              </div>
            )}
          </div>
          <div className="space-y-1">
            <WrappedInput
              data-test="input-password"
              label="Password"
              name="password"
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              as="password"
              toggleShowPassword={toggleShowPassword}
              instructions="Password must have at least 8 characters and include a mix of letters and numbers."
            />
          </div>

          <div className="space-y-1">
            <MultiSelect
              simpleValue
              labelClass="label font-bold mb-2 leading-18px"
              aria-label="How did you hear about us?"
              label="How did you hear about us? (Optional)"
              name="referral_source"
              placeholder="Select how you heard about us"
              options={[
                {
                  label: "Friend/family/colleague",
                  value: "friend_family_colleague",
                },
                {
                  label: "Forestry professional",
                  value: "forestry_professional",
                },
                {
                  label: "Event/webinar",
                  value: "event_webinar",
                },
                {
                  label: "Newspaper/magazine",
                  value: "newspaper_magazine",
                },
                {
                  label: "Social media/internet search",
                  value: "social_media_internet_search",
                },
                {
                  label: "Postcard/direct mail",
                  value: "postcard_direct_mail",
                },
                {
                  label: "Other",
                  value: "other",
                },
              ]}
            />
          </div>

          <div>
            <SubmitButton
              className="btn2 btn2-primary btn2-block font-semibold mt-6"
              isSubmitting={isSubmitting}
              data-test="sign-up"
            >
              Create Account
            </SubmitButton>
          </div>

          <SignUpFooter
            secondaryInfo={
              <Link to="/getting-started" className="link--underline-only">
                Already have an account? <span className="link">Log in</span>
              </Link>
            }
          />
        </div>
      </Form>
    </ContentCard>
  )
}

export default SignUpFormComponent
