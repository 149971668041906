import cx from "classnames"

interface OnbordingOverviewAssetProps {
  image: string
  text: string
  className?: string
  textClass?: string
}

const OnbordingOverviewAsset = ({
  image,
  text,
  className,
  textClass,
}: OnbordingOverviewAssetProps) => {
  return (
    <div
      className={cx(
        "flex flex-col justify-center gap-[18px] md:gap-8",
        className
      )}
    >
      <img
        src={image}
        alt={text}
        className="block h-auto w-[150px] md:w-auto self-center"
      />

      <h3
        className={cx(
          "text-charcoal-500 text-lg md:text-2xl text-center font-medium leading-[124%] tracking-0.36 md:tracking-0.48",
          textClass
        )}
      >
        {text}
      </h3>
    </div>
  )
}

export default OnbordingOverviewAsset
