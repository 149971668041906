export interface ProjectCardNameProps {
  name: string
}

const ProjectCardName = ({ name }: ProjectCardNameProps) => (
  <div className="line-clamp-2 mb-2">
    <h3 className="font-semibold text-lg leading-130 mb-0">{name}</h3>
  </div>
)

export default ProjectCardName
