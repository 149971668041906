import NumberedListItem from "../../components/NumberedListItem"
import ContentCard from "../../components/ContentCard"
import { AuthLayoutTypes } from "../../types/auth"

const ProSignUpLayout = ({ form }: AuthLayoutTypes) => (
  <div className="container max-w-xl flex flex-auto px-0 sm:px-4 lg:py-7.5">
    <div className="w-full flex sm:self-center flex-col lg:flex-row items-center lg:items-start lg:justify-between lg:gap-8">
      <div className="w-full sm:max-w-[460px] flex flex-col text-white bg-pro-signup-img sm:bg-none sm:bg-transparent px-4 sm:px-none py-16 sm:py-none">
        <h1 className="text-xl lg:text-3xl leading-120 tracking-0.4 lg:tracking-0.9">
          Join the NCX Pro Network and Get Access to Dozens of Natural Capital
          Programs
        </h1>

        <ol className="list-none mt-[34px] lg:mt-16 relative">
          <NumberedListItem
            heading="Sign Up"
            text="This one account gives you access to dozens of natural capital programs."
            className="before:content-['1'] mb-8"
          />

          <NumberedListItem
            heading="Advise Your Clients"
            text="Use the NCX Pro Network platform to connect your clients to the right programs."
            className="before:content-['2'] mb-8"
          />

          <NumberedListItem
            heading="Earn Commission"
            text="Get a commission when your clients go under contract with eligible programs on the platform."
            className="before:content-['3']"
          />
        </ol>
      </div>

      <div className="flex flex-col w-full sm:max-w-440 flex-grow sm:pb-16 lg:pt-16">
        <ContentCard className="rounded-none sm:rounded grow sm:grow-0 pb-7 sm:pb-0 sm:px-8 md:px-0">
          {form}
        </ContentCard>{" "}
      </div>
    </div>
  </div>
)

export default ProSignUpLayout
