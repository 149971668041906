import { ReactNode } from "react"
import cx from "classnames"

import IconCard from "./IconCard"

export interface SuccessCardProps {
  className?: string
  children: ReactNode
}

const SuccessCard = ({ className, children }: SuccessCardProps) => (
  <IconCard
    className={cx("card-success", className)}
    icon={<i className="far fa-check-circle" />}
    dataTest="success-card"
  >
    {children}
  </IconCard>
)

export default SuccessCard
