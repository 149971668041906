import { useDialogState } from "reakit/Dialog"

import LandExplanatoryText from "./LandText/LandExplanatoryText"
import LandCoverClassesModal from "./LandText/LandCoverClassesModal"

const LandText = () => {
  const landCoverDialog = useDialogState({ animated: true })

  return (
    <>
      <LandExplanatoryText onClick={landCoverDialog.show} />

      <LandCoverClassesModal dialog={landCoverDialog} />
    </>
  )
}

export default LandText
