import { DateTime } from "luxon"

import Stat from "../../components/Stat"
import { AccountProperty } from "@/types/property"

interface ThreatsStatsProps {
  property: AccountProperty | undefined
  isMember: boolean | undefined
  date: string | undefined
}

const ThreatsStats = ({ property, isMember, date }: ThreatsStatsProps) => {
  const lossDataNotReady =
    property?.change_data === null || property?.change_data === undefined

  const newDate = property?.change_data ? property.change_data.date : date

  const formattedDate = newDate
    ? DateTime.fromISO(newDate).toLocaleString({
        month: "short",
        day: "numeric",
        year: "numeric",
      })
    : "-"

  const blur = isMember ? "" : "blur-sm"

  const majorLoss = lossDataNotReady
    ? "-"
    : property?.change_data?.major_loss
      ? Number(property.change_data.major_loss.toFixed(1))
      : 0

  const minorLoss = lossDataNotReady
    ? "-"
    : property?.change_data?.minor_loss
      ? Number(property.change_data.minor_loss.toFixed(1))
      : 0

  return (
    <div className="w-full inline-flex flex-wrap gap-6 lg:gap-9">
      <Stat
        label="Detection Date"
        value={formattedDate}
        showPct={false}
        valueClass={blur}
      />

      <Stat
        label="Acres of Major Loss"
        value={majorLoss}
        showPct={false}
        valueClass={blur}
      />

      <Stat
        label="Acres of Minor Loss"
        value={minorLoss}
        showPct={false}
        valueClass={blur}
      />
    </div>
  )
}

export default ThreatsStats
