const ProjectVerificationBadgeLearnMoreLink = () => (
  <a
    href="https://help.ncx.com/hc/en-us/articles/27881997151131-Program-Verification-Status"
    rel="noopener noreferrer"
    target="_blank"
    className="link"
  >
    Learn More
  </a>
)

export default ProjectVerificationBadgeLearnMoreLink
