import OnboardingOverviewContainer from "./OnboardingOverviewContainer"
import OnbordingOverviewAsset from "./OnbordingOverviewAsset"
import FOREST_CARBON from "../../../images/onboarding-overview-forest-carbon.svg"
import TIMBER from "../../../images/onboarding-overview-timber.svg"
import FOREST_HEALTH from "../../../images/onboarding-overview-forest-health.svg"

interface OnboardingOverviewAssetsProps {
  handleOverviewStep: () => void
}

const OnboardingOverviewAssets = ({
  handleOverviewStep,
}: OnboardingOverviewAssetsProps) => {
  return (
    <OnboardingOverviewContainer className="pt-[24px] md:pt-24">
      <div className="max-w-fit mx-auto">
        <h1 className="text-charcoal-500 text-2xl font-body font-medium leading-[124%] tracking-0.48">
          Thank you for sharing your goals! Let's review your Natural Capital
          Portfolio.
        </h1>

        <p className="text-dusk text-base leading-[140%] tracking-0.32 mt-3">
          Your Portfolio is made up of Natural Capital Assets like the ones
          below.
        </p>
      </div>

      <div className="flex flex-col md:flex-row md:justify-center md:items-end gap-6 md:gap-20 lg:gap-[140px] mt-[34px] md:mt-[116px]">
        <OnbordingOverviewAsset image={FOREST_CARBON} text="Forest Carbon" />

        <OnbordingOverviewAsset image={TIMBER} text="Timber" />

        <OnbordingOverviewAsset image={FOREST_HEALTH} text="Forest Health" />
      </div>

      <div className="flex justify-end mt-[30px] md:mt-[46px] pb-[33px]">
        <button className="btn2 btn2-primary" onClick={handleOverviewStep}>
          Next
        </button>
      </div>
    </OnboardingOverviewContainer>
  )
}

export default OnboardingOverviewAssets
