import cx from "classnames"

export interface ProjectCardImageProps {
  imageUrl: string
  isVerified: boolean
  alt: string
}

const ProjectCardImage = ({
  imageUrl,
  isVerified,
  alt,
}: ProjectCardImageProps) => (
  <div className="mb-3">
    <img
      src={imageUrl}
      alt={alt}
      className={cx("w-full h-121 sm:h-222 object-cover rounded", {
        grayscale: !isVerified,
      })}
    />
  </div>
)

export default ProjectCardImage
