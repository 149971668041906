import { Source, Layer } from "@urbica/react-map-gl"

import { TileURLsTypes } from "@/types/tiles"

export interface EligibilityLayerTypes {
  showEligibilityLayer: boolean
  tileData?: TileURLsTypes | null | undefined
}

export const EligibilityLayer = ({
  showEligibilityLayer,
  tileData,
}: EligibilityLayerTypes) => {
  if (!showEligibilityLayer || !tileData) {
    return null
  }

  if (!tileData?.url && tileData?.raster.min.length === 0) {
    return (
      <>
        <Source
          id="default"
          type="vector"
          url={tileData.vector}
          data-testid="source"
        />
        <Layer
          id="default"
          source="default"
          source-layer="default"
          type="fill"
          paint={{
            "fill-color": "#3BB2D0",
            "fill-opacity": 0.8,
          }}
          data-testid="layer"
        />
      </>
    )
  }

  return (
    <>
      <Source
        id="default"
        type="raster"
        tiles={tileData?.url ? [tileData?.url] : tileData?.raster.min}
        data-testid="source"
      />
      <Layer id="default" source="default" type="raster" data-testid="layer" />
    </>
  )
}

export default EligibilityLayer
