import cx from "classnames"

import PaginationLink from "./PaginationLink"
import PaginationPrev from "./PaginationPrev"
import PaginationNext from "./PaginationNext"

export interface PaginationProps {
  prevTo: string | undefined
  nextTo: string | undefined
  prevLinkText: string
  nextLinkText: string
  className: string
}

const Pagination = ({
  prevTo,
  nextTo,
  prevLinkText,
  nextLinkText,
  className,
}: PaginationProps) => {
  return (
    <div className={cx("pagination", className)} data-testid="pagination">
      <div className="pagination-container container">
        <PaginationLink to={prevTo} className="lg:mr-8">
          <PaginationPrev prevLinkText={prevLinkText} />
        </PaginationLink>

        <PaginationLink to={nextTo} className="lg:ml-8">
          <PaginationNext nextLinkText={nextLinkText} />
        </PaginationLink>
      </div>
    </div>
  )
}

export default Pagination
