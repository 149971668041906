const QuestionExamples = () => (
  <div className="space-y-p my-7">
    <p className="text-base text-charcoal-500 font-bold">
      Not sure where to start? Here are some questions we've received from
      landowners like you.
    </p>

    <p className="text-leaf">Is carbon or timber a better deal on my land?</p>

    <p className="text-leaf">
      Which program payments can I stack to earn more passive income from my
      woods?
    </p>

    <p className="text-leaf">
      What is the best program combo for a recreational property?
    </p>
  </div>
)

export default QuestionExamples
