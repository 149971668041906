import { ReactNode } from "react"
import cx from "classnames"

interface ProjectMapSkeletonTypes {
  message?: ReactNode
  animate?: boolean
}

const ProjectMapSkeleton = ({
  message,
  animate = false,
}: ProjectMapSkeletonTypes) => (
  <div
    className={cx("w-full xl:flex", {
      "animate-pulse": animate,
    })}
  >
    <div
      className={cx(
        "details-hero-img relative h-[400px] max-w-full mb-0 bg-dusk-200",
        "rounded-t rounded-b-none",
        "lg:min-w-full xl:min-w-250 xl:grow xl:rounded-l xl:rounded-tr-none"
      )}
    >
      {message}
    </div>

    <div
      className={cx(
        "w-full",
        "border-l-1 border-r-1 border-b-1 border-dusk-200",
        "rounded-b",
        "xl:h-[400px] xl:w-[244px] xl:flex xl:flex-col xl:order-2 xl:rounded-tr xl:rounded-bl-none"
      )}
    >
      <div
        className={cx(
          "flex lg:order-3",
          "mt-auto mb-0 px-4 py-2",
          "border-dusk-200",
          "xl:order-3 xl:border-t-1"
        )}
      >
        <div className="h-10 grow bg-dusk-200 rounded mb-4 sm:mb-auto mr-4" />
        <div className="h-10 grow bg-dusk-200 rounded" />
      </div>

      <div className="w-full flex xl:order-2 items-center gap-4 border-b-1 border-t-1 border-dusk-200 p-2">
        <div className="bg-dusk-200 rounded grow h-5" />
        <div className="bg-dusk-200 rounded w-12 h-12" />
      </div>

      <div className="w-full flex xl:order-2 items-center gap-4 border-dusk-200 xl:border-b-1 p-2">
        <div className="bg-dusk-200 rounded grow h-5" />
        <div className="bg-dusk-200 rounded w-12 h-12" />
      </div>
    </div>
  </div>
)

export default ProjectMapSkeleton
