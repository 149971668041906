import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"

export interface PaginationPrevProps {
  prevLinkText: string
}

const PaginationPrev = ({ prevLinkText }: PaginationPrevProps) => (
  <>
    <span className={cx("pagination-link-icon", "mr-2")}>
      <FontAwesomeIcon icon={faArrowLeft} title={faArrowLeft.iconName} />
    </span>

    <span className="text-base font-semibold">
      {prevLinkText || "Previous"}
    </span>
  </>
)

export default PaginationPrev
