import { useMemo, useState } from "react"
import { useQueryClient } from "@tanstack/react-query"

import { ProgressBar } from "../../components/ProgressBar"
import MainLayout from "../_layouts/Main"
import OnboardingOverviewSteps from "../../sections/Onboarding/OnboardingOverview/OnboardingOverviewSteps"
import { useAccountId, useProjectList } from "../../hooks"
import { getProjectsData } from "../../shared/utils"
import { ProjectListTypes, ProjectDataTypes } from "@/types/program"

const OnboardingOverview = () => {
  const [overviewStep, setOverviewStep] = useState<number>(1)
  const queryClient = useQueryClient()
  const accountId = useAccountId()

  const { data: projectList, isLoading: projectListIsLoading } = useProjectList<
    ProjectListTypes,
    Error
  >(queryClient, accountId, {})

  const programsData: ProjectDataTypes[] = useMemo(() => {
    return getProjectsData(projectList)
  }, [projectList])

  const handleOverviewStep = () => {
    setOverviewStep((oldStep) => oldStep + 1)
  }

  return (
    <MainLayout
      isLoading={[projectListIsLoading]}
      contentBg="white"
      className="onboarding"
    >
      <ProgressBar
        className="onboarding-survey-progress-bar w-full h-5"
        value={overviewStep}
        max={3}
      />

      <OnboardingOverviewSteps
        overviewStep={overviewStep}
        handleOverviewStep={handleOverviewStep}
        programsData={programsData}
      />
    </MainLayout>
  )
}

export default OnboardingOverview
