import { Link } from "react-router"
import cx from "classnames"

import ProjectCardInnactiveBadge from "./ProjectCardInnactiveBadge"
import ProjectCardVerificationBadge from "./ProjectCardVerificationBadge"
import ProjectCardStatusBadge from "./ProjectCardStatusBadge"
import ProjectCardImage from "./ProjectCardImage"
import ProjectCardName from "./ProjectCardName"
import ProjectCardDescription from "./ProjectCardDescription"
import ProjectCardIcons from "./ProjectCardIcons"
import { getProjectBadgeType, getProjectBadgeText } from "../../shared/utils"
import { useAccountUrlPrefix } from "../../hooks"
import { ProjectDataTypes } from "@/types/program"

export interface ProjectCardTypes {
  className?: string
  programData: ProjectDataTypes
  showMinimalInfo?: boolean
  shortened?: boolean
  onClick?: () => void
  disabled?: boolean
}

const ProjectCard = ({
  className,
  programData,
  showMinimalInfo = false,
  shortened = false,
  onClick,
  disabled = false,
}: ProjectCardTypes) => {
  const accountUrlPrefix = useAccountUrlPrefix()
  const badgeType = getProjectBadgeType(programData.badge_display)
  const badgeText = getProjectBadgeText(programData.badge_display)

  return (
    <div className={cx("relative flex", { "pointer-events-none": disabled })}>
      <ProjectCardInnactiveBadge
        showMinimalInfo={showMinimalInfo}
        isActive={programData?.is_active}
      />

      <ProjectCardVerificationBadge
        showMinimalInfo={showMinimalInfo}
        isVerified={programData.is_verified}
      />

      <Link
        to={`${accountUrlPrefix}/programs/${programData.id}`}
        className={cx(
          "project-card min-w-full",
          programData?.is_active === false
            ? "outline outline-[#f9303f] outline-[6px] -outline-offset-[6px]"
            : "",
          className
        )}
        aria-label={programData.name}
        onClick={onClick}
      >
        <ProjectCardStatusBadge
          showMinimalInfo={showMinimalInfo}
          badgeType={badgeType}
          badgeText={badgeText}
          isSponsored={programData.is_sponsored}
        />

        <ProjectCardImage
          imageUrl={programData.image_url}
          isVerified={programData.is_verified}
          alt={programData.name}
        />

        <ProjectCardName name={programData.name} />

        <ProjectCardDescription
          showMinimalInfo={showMinimalInfo}
          description={programData.description_short}
        />

        <ProjectCardIcons
          showMinimalInfo={showMinimalInfo}
          shortened={shortened}
          programData={programData}
        />
      </Link>
    </div>
  )
}

export default ProjectCard
