import cx from "classnames"
import { DialogDisclosure, useDialogState } from "reakit/Dialog"

import { Modal } from "../Modal"
import HelpCardContent from "./HelpCardContent"
import { HelpCardTypes } from "./types"

const helpCardClassName = "card card-content-inner border-light"

const HelpCard = ({ items, className }: HelpCardTypes) => {
  const dialog = useDialogState({ animated: true })

  return (
    <>
      <DialogDisclosure
        {...dialog}
        className={cx(helpCardClassName, "w-full md:hidden", className)}
        data-testid="help-card-dialog-disclosure"
      >
        <div className="flex flex-row">
          <span className="shrink-0 mr-3.5">
            <i className="fas fa-lightbulb-on fa-lg text-leaf"></i>
          </span>

          <div className="space-y-4">
            {items.map((item, i) => (
              <p key={i} className="text-left text-leaf">
                <strong>{item.title}</strong>
              </p>
            ))}
          </div>
        </div>
      </DialogDisclosure>

      <Modal
        header={<i className="fas fa-lightbulb-on fa-lg text-leaf"></i>}
        aria-label="Help dialog"
        dialog={dialog}
        data-testid="help-card-modal"
      >
        <HelpCardContent items={items} />
      </Modal>

      {/* Medium and larger content */}
      <div className={cx(helpCardClassName, "hidden  md:block", className)}>
        <p className="mb-4">
          <i className="fas fa-lightbulb-on fa-lg text-leaf"></i>
        </p>

        <HelpCardContent items={items} />
      </div>
    </>
  )
}

export default HelpCard
