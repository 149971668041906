import ONBOARDING from "../../images/onboarding-survey.webp"

const AskAQuestionImage = () => (
  <div className="hidden md:block w-auto max-w-[528px]">
    <img
      src={ONBOARDING}
      alt="Ebony Tree Image"
      className="w-auto max-h-[480px]"
    />
  </div>
)

export default AskAQuestionImage
