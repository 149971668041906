import { Link } from "react-router"

import OnboardingOverviewContainer from "./OnboardingOverviewContainer"
import ProjectCard from "../../../components/ProjectCard"
import { useAccountUrlPrefix } from "../../../hooks"
import { ProjectDataTypes } from "@/types/program"

interface OnboardingOverviewProgramsProps {
  programsData: ProjectDataTypes[]
}

const OnboardingOverviewPrograms = ({
  programsData,
}: OnboardingOverviewProgramsProps) => {
  const accountUrlPrefix = useAccountUrlPrefix()

  const programs: ProjectDataTypes[] = programsData.filter(
    (program) => program.id === 3 || program.id === 11 || program.id === 100
  )

  return (
    <OnboardingOverviewContainer>
      <div className="w-full flex flex-col-reverse xl:flex-row xl:justify-between gap-[30px] xl:gap-14 pt-[24px] xl:pt-24">
        <div className="grid md:grid-cols-3 gap-2 w-full">
          {programs.map((program) => (
            <ProjectCard
              key={program.id}
              programData={program}
              className={"xl:w-[218px]"}
              showMinimalInfo
              shortened
              disabled
            />
          ))}
        </div>

        <div className="xl:w-[500px] w-full">
          <h1 className="text-charcoal-500 text-2xl font-body font-medium leading-[124%] tracking-0.48">
            Generate income from your Assets with Programs.
          </h1>

          <p className="text-dusk text-base font-body leading-[140%] tracking-0.32 mt-3">
            Programs are income opportunities for your assets. Through leasing
            or selling the rights to your assets for a period of time, you can
            turn your assets into cash.
          </p>
        </div>
      </div>

      <div className="w-full flex justify-end mt-[30px] xl:mt-[148px] pb-[33px]">
        <Link
          to={`${accountUrlPrefix}/become-a-member`}
          className="btn2 btn2-primary"
        >
          Next
        </Link>
      </div>
    </OnboardingOverviewContainer>
  )
}

export default OnboardingOverviewPrograms
