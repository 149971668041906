import { shortenAcreage } from "../../../utils"

interface ProjectMapParcelsListFeatureContentProps {
  enableEligibility: boolean
  eligAcres: number
  parcelAcres: number
}

const ProjectMapParcelsListFeatureContent = ({
  enableEligibility,
  eligAcres,
  parcelAcres,
}: ProjectMapParcelsListFeatureContentProps) => {
  if (enableEligibility) {
    return (
      <div>
        <strong className="font-bold">
          {shortenAcreage(eligAcres)}/{shortenAcreage(parcelAcres)}
        </strong>{" "}
        acres eligible
      </div>
    )
  }

  return (
    <div>
      <strong className="font-bold">{shortenAcreage(parcelAcres)}</strong> acres
    </div>
  )
}

export default ProjectMapParcelsListFeatureContent
