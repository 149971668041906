import { ReactElement } from "react"
import { Link } from "react-router"
import cx from "classnames"
import { Form, FormikProps } from "formik"

import ErrorCard from "../../components/ErrorCard"
import { SubmitButton } from "../../components/SubmitButton"
import { WrappedInput } from "../../components/Input"
import { Checkbox2 } from "../../components/Checkbox"
import { AcreageToolTip } from "../../components/AcreageToolTip"
import { ToolTip } from "../../components/ToolTip"
import { ActionPermissionWrapper } from "../../components/ActionPermissionWrapper"
import { ButtonPair } from "../../components/ButtonPair"
import { useAccountUrlPrefix, useIsMultiAccount } from "../../hooks"
import { shortenAcreage } from "../../utils"
import { OWNERSHIP_TYPE_OPTIONS } from "./PropertyDetailsFormConstants"
import BOUNDARIES from "../../images/boundaries.svg"
import {
  requiresLegalEntityName,
  resetDependentFields,
} from "./PropertyDetailsFormHelpers"
import { AccountTypes } from "../../types/account"
import { AccountProperty } from "../../types/property"

interface PropertyDetailsFormComponentTypes {
  backLink: ReactElement<any, any>
  errorMessage?: string | null
  formikProps: FormikProps<AccountTypes>
  property: AccountProperty
  submitText: string
}

const PropertyDetailsFormComponent = ({
  backLink,
  errorMessage,
  formikProps,
  property,
  submitText,
}: PropertyDetailsFormComponentTypes) => {
  const accountUrlPrefix = useAccountUrlPrefix()
  const isMultiAccount = useIsMultiAccount()

  const legalEntityNameLabel = requiresLegalEntityName(
    formikProps.values.ownership_type
  )
    ? "Legal entity name"
    : "Legal entity name (Optional)"

  return (
    <Form className="max-w-xs  md:max-w-1.5xl">
      {errorMessage && <ErrorCard className="mb-6">{errorMessage}</ErrorCard>}

      <div className="mb-6">
        <label className="label font-semibold mb-1.5">Boundaries</label>

        <div className="rounded border border-cloud p-4">
          <div className="flex justify-between items-center">
            <div className="text-carbon inline-block">
              <img
                className="inline-block"
                src={BOUNDARIES}
                alt="Boundaries illustration"
              />
              <span className="align-bottom">
                <span className="ml-4 font-light text-3xl leading-7">
                  {property?.acreage ? shortenAcreage(property.acreage) : "-"}
                </span>{" "}
                acres <AcreageToolTip />
              </span>
            </div>

            <Link
              aria-label="View property boundaries"
              // DEV: This only link styles "View Property Boundaries" (as per `link.css` rules)
              //   as the border for this section is more of a card, not exclusively for the link
              className="link"
              to={`${accountUrlPrefix}/settings/property-boundaries`}
            >
              View Property Boundaries
            </Link>
          </div>
        </div>
      </div>

      <div className="mb-6">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-3">
          <div>
            <WrappedInput
              as="combobox"
              label="Ownership type"
              aria-label="Ownership type"
              name="ownership_type"
              onChange={(evt) => {
                resetDependentFields({ formikProps, evt })
                formikProps.setFieldValue(evt.target.name, evt.target.value)
              }}
              options={OWNERSHIP_TYPE_OPTIONS}
            />
          </div>
          <div>
            <WrappedInput
              label={legalEntityNameLabel}
              aria-label={legalEntityNameLabel}
              placeholder="Legal entity name"
              type="text"
              name="legal_entity_name"
            />
          </div>
        </div>
      </div>
      {
        <>
          {!isMultiAccount ? (
            <div className="mb-6">
              <Checkbox2
                name="works_with_consulting_forester"
                label="A forester, manager, or advisor referred me to NCX"
                aria-label="A forester, manager, or advisor referred me to NCX"
                tabIndex={0}
              />
            </div>
          ) : null}

          {formikProps.values.works_with_consulting_forester ? (
            <>
              <div className="mb-4">
                <span className="font-semibold">Advisor Details</span>{" "}
              </div>

              <div className="mb-6">
                <div className="mb-4 grid grid-cols-1 gap-4  md:grid-cols-2 md:gap-3">
                  <div>
                    <WrappedInput
                      label="Full name"
                      aria-label="Full name"
                      placeholder="Advisor name"
                      type="text"
                      name="consulting_forester_name"
                    />
                  </div>
                  <div>
                    <WrappedInput
                      label={
                        <>
                          Company{" "}
                          <ToolTip aria-label="Enter 'n/a' if there is no company name.">
                            Enter 'n/a' if there is no company name.
                          </ToolTip>
                        </>
                      }
                      aria-label="Company"
                      placeholder="Company"
                      type="text"
                      name="consulting_forester_company"
                    />
                  </div>
                </div>

                <div className="mb-0 grid grid-cols-1 gap-4  md:grid-cols-2 md:gap-3">
                  <div>
                    <WrappedInput
                      label="Phone"
                      aria-label="Phone"
                      placeholder="Phone"
                      type="text"
                      name="consulting_forester_phone_number"
                    />
                  </div>
                  <div>
                    <WrappedInput
                      label="Email"
                      aria-label="Email"
                      placeholder="Email"
                      type="text"
                      name="consulting_forester_email"
                      as="email"
                    />
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </>
      }

      <ButtonPair
        gapClass="gap-2"
        // eslint-disable-next-line react/no-unstable-nested-components
        primary={(primaryProps) => (
          <ActionPermissionWrapper
            accountRole={formikProps.values.role}
            action="editAccount"
          >
            <SubmitButton
              isSubmitting={formikProps.isSubmitting}
              {...primaryProps}
              className={cx(primaryProps.className, "py-[9px]")}
            >
              {submitText}
            </SubmitButton>
          </ActionPermissionWrapper>
        )}
        secondary={
          <backLink.type
            {...backLink.props}
            className="btn2 btn2-outline-primary font-semibold"
          />
        }
      />
    </Form>
  )
}

export default PropertyDetailsFormComponent
