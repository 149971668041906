import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconDefinition as IconDefinitionSvg } from "@fortawesome/fontawesome-svg-core"

export interface ArrowTypes {
  onClick: () => void
  icon: IconDefinitionSvg
}

const SimilarProgramsSliderArrow = ({ onClick, icon }: ArrowTypes) => (
  <button
    type="button"
    onClick={onClick}
    className="similar-programs-slider-arrow"
    data-testid={icon.iconName}
  >
    <FontAwesomeIcon icon={icon} title={icon.iconName} />
  </button>
)

export default SimilarProgramsSliderArrow
