import { ReactElement, useState } from "react"
import cx from "classnames"
import * as Yup from "yup"
import { Form, Formik, FormikProps, FormikValues } from "formik"
import { useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { genericErrMsg, isAuthenticationError } from "../api/auth"
import ErrorCard from "../components/ErrorCard"
import HelperCard from "../components/HelperCard"
import { SubmitButton } from "../components/SubmitButton"
import { WrappedInput } from "../components/Input"
import { useChangePassword } from "../hooks"

interface FormValuesTypes {
  currentPassword: string
  newPassword: string
  confirmPassword: string
}

interface FormComponentTypes {
  backLink: ReactElement<any, any>
  className: string
  errorMessage: any
  formikProps: FormikProps<FormValuesTypes>
  submitText: string
}

interface ChangePasswordFormTypes {
  backLink: ReactElement
  submitText: string
  onSuccess: () => void
}

interface ErrorResponseData {
  detail?: string
}

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required("Please enter your current password."),
  newPassword: Yup.string()
    .required("Please provide a new password.")
    .min(8, "New password must be at least 8 characters."),
  confirmPassword: Yup.string()
    .required("Please re-enter your new password.")
    .when("newPassword", {
      is: (val: string) => val && val.length > 0,
      then: () =>
        Yup.string().oneOf(
          [Yup.ref("newPassword")],
          "New passwords must match."
        ),
    }),
})

const FormComponent = ({
  backLink,
  className,
  errorMessage,
  formikProps,
  submitText,
}: FormComponentTypes) => {
  const [showCurrentPassword, toggleShowCurrentPassword] = useState(false)
  const [showNewPassword, toggleShowNewPassword] = useState(false)
  const [showConfirmNewPassword, toggleShowConfirmNewPassword] = useState(false)

  return (
    <Form className={cx("max-w-xs  md:max-w-1.5xl", className)}>
      {errorMessage && <ErrorCard className="mb-6">{errorMessage}</ErrorCard>}
      <div className="mb-4 grid grid-cols-1 gap-4  md:grid-cols-2 md:gap-3">
        <div>
          <WrappedInput
            label="Current password"
            name="currentPassword"
            as="password"
            type={showCurrentPassword ? "text" : "password"}
            toggleShowPassword={() =>
              toggleShowCurrentPassword((prev) => !prev)
            }
          />
        </div>
      </div>

      <div className="mb-6 grid grid-cols-1 gap-4  md:grid-cols-2 md:gap-3">
        <div>
          <WrappedInput
            label="New password"
            name="newPassword"
            as="password"
            type={showNewPassword ? "text" : "password"}
            toggleShowPassword={() => toggleShowNewPassword((prev) => !prev)}
            onChange={(evt) => {
              // Reset `confirmPassword` error (silences "passwords not matching" until new touch)
              formikProps.setFieldTouched("confirmPassword", false)

              // Perform normal setter
              formikProps.setFieldValue(evt.target.name, evt.target.value)
            }}
          />
        </div>

        <div>
          <WrappedInput
            label="Confirm new password"
            name="confirmPassword"
            as="password"
            type={showConfirmNewPassword ? "text" : "password"}
            toggleShowPassword={() =>
              toggleShowConfirmNewPassword((prev) => !prev)
            }
          />
        </div>
      </div>

      <HelperCard className="w-full mb-6">
        <h4 className="text-charcoal-500 text-base leading-130 tracking-0.32 font-semibold">
          Tips for a strong password
        </h4>
        <ul className="ul-custom text-charcoal-500 text-base leading-130 tracking-0.32 pl-5 mt-2">
          <li>Don't use a previous password</li>
          <li>Don't use your name or email in the password</li>
          <li>Use a mix of numbers and symbols</li>
        </ul>
      </HelperCard>

      <p>
        {/* https://reactjs.org/docs/react-api.html#cloneelement */}
        <backLink.type
          {...backLink.props}
          className="btn2 btn2-outline-primary font-semibold"
        />
        <SubmitButton
          isSubmitting={formikProps.isSubmitting}
          className="ml-2 btn2 btn2-primary font-semibold"
        >
          {submitText}
        </SubmitButton>
      </p>
    </Form>
  )
}

// DEV: Use params to filter out props exclusive to this component (not FormComponent)
const ChangePasswordForm = ({
  backLink,
  submitText,
  onSuccess,
}: ChangePasswordFormTypes) => {
  const queryClient = useQueryClient()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const { mutateAsync: changePassword } = useChangePassword(queryClient, {
    onSuccess,
    onError: (error: AxiosError) => {
      let errMsg =
        (error?.response?.data as ErrorResponseData)?.detail ||
        error?.message ||
        genericErrMsg
      if (isAuthenticationError(error)) {
        errMsg = "You've entered an incorrect current password."
      }
      setErrorMessage(errMsg)
    },
  })

  const handleSubmit = async (values: FormikValues) => {
    setErrorMessage(null)
    await changePassword({
      current_password: values.currentPassword,
      new_password: values.newPassword,
    })
  }

  return (
    // DEV: Avoid `enableReinitialize=true`, see https://app.asana.com/0/0/1200063952843264/f
    //   This comment may now be out of date after https://app.asana.com/0/0/1201367694639660/1201368096424394/f
    <Formik
      enableReinitialize={false}
      initialValues={{
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      }}
      validationSchema={validationSchema}
      validateOnBlur={true}
      validateOnChange={true}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <FormComponent
          className="mb-10  md:mb-0 md:order-2 md:w-full"
          errorMessage={errorMessage}
          formikProps={formikProps}
          backLink={backLink}
          submitText={submitText}
        />
      )}
    </Formik>
  )
}

export default ChangePasswordForm
