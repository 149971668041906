import { useEffect } from "react"
import { Link } from "react-router"
import { DisclosureStateReturn } from "reakit/Disclosure"
import cx from "classnames"

import NCXLogo from "../../images/NCX---Logo---Full---White.png"
import SlideInNavLinks from "./SlideInNavLinks"
import SlideInNavClose from "./SlideInNavClose"

export interface SlideInNavTypes {
  disclosure: DisclosureStateReturn
  isPartnersNav: boolean
}

const SlideInNav = ({ disclosure, isPartnersNav }: SlideInNavTypes) => {
  useEffect(() => {
    if (!disclosure.visible) return

    // prevent page scroll when navigation is open
    document.body.classList.add("overflow-y-hidden")

    return () => {
      document.body.classList.remove("overflow-y-hidden")
    }
  }, [disclosure.visible])

  return (
    <>
      <button
        role="button"
        onClick={disclosure.hide}
        className={cx("slide-in-nav-overlay", {
          "slide-in-nav-overlay-visible": disclosure.visible,
        })}
        aria-label="slide-in-nav-overlay"
      />

      <div className="slide-in-nav-content">
        <SlideInNavClose disclosure={disclosure} />

        <div className="flex flex-col items-center justify-between w-full grow">
          <SlideInNavLinks
            isPartnersNav={isPartnersNav}
            disclosure={disclosure}
          />

          <div className="flex items-center justify-center">
            <Link
              aria-label="Go to dashboard"
              to="/"
              className="p-2"
              onClick={disclosure.hide}
            >
              <img alt="NCX Logo" className="h-11" src={NCXLogo} />
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default SlideInNav
