import { ReactNode } from "react"
import cx from "classnames"
import StickyNotificationClose from "./StickyNotificationClose"
import StickyNotificationTitle from "./StickyNotificationTitle"

export interface StickyNotificationTypes {
  title: string | null
  className?: string | null
  children: ReactNode
  variant?: "warning" | "success"
  onDismiss?: () => void
  sticky?: boolean
}

const StickyNotification = ({
  title,
  className,
  children,
  variant = "warning",
  onDismiss,
  sticky = false,
}: StickyNotificationTypes) => (
  <div
    className={cx(
      "flex flex-row text-white relative z-9 py-3",
      { "bg-orange-900": variant === "warning" },
      { "bg-grass-900": variant === "success" },
      { "sticky top-0": sticky },
      className
    )}
  >
    <div className="container flex-auto">
      <StickyNotificationTitle title={title} />

      <div>{children}</div>
    </div>

    <StickyNotificationClose onDismiss={onDismiss} />
  </div>
)

export default StickyNotification
