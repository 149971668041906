import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"

interface ProjectMapParcelsBtnProps {
  onClick: () => void
  icon: IconDefinition
}

const ProjectMapParcelsBtn = ({ onClick, icon }: ProjectMapParcelsBtnProps) => (
  <button
    type="button"
    className="grow btn2 btn2-outline-primary text-dusk-500 p-[10px]"
    onClick={onClick}
  >
    <FontAwesomeIcon icon={icon} />
  </button>
)

export default ProjectMapParcelsBtn
