import { ReactElement } from "react"
import { useTooltipState } from "reakit/Tooltip"
import TooltipTrigger from "./TooltipTrigger"
import TooltipContent from "./TooltipContent"

export interface TooltipTypes {
  children: ReactElement | string
  className?: string
}

const ToolTip = ({ children, className }: TooltipTypes) => {
  const tooltip = useTooltipState()

  return (
    <>
      <TooltipTrigger className={className} tooltip={tooltip} />
      <TooltipContent tooltip={tooltip}>{children}</TooltipContent>
    </>
  )
}

export default ToolTip
