import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"

export interface PaginationNextProps {
  nextLinkText: string
}

const PaginationNext = ({ nextLinkText }: PaginationNextProps) => (
  <>
    <span className="text-base font-semibold">{nextLinkText || "Next"}</span>

    <span className={cx("pagination-link-icon", "ml-2")}>
      <FontAwesomeIcon icon={faArrowRight} title={faArrowRight.iconName} />
    </span>
  </>
)

export default PaginationNext
