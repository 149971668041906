import * as yup from "yup"

import {
  phoneRegex,
  PREFERRED_CONTACT_METHOD,
  PHONE_NUMBER,
} from "../../shared/constants"

export const QUESTION = "question"

export const needsPhoneValidation = (methods: string[]): boolean =>
  methods.includes("phone") || methods.includes("text")

export const validationSchema = (isValidPhoneNumber: boolean) =>
  yup.object().shape({
    [PREFERRED_CONTACT_METHOD]: yup
      .array()
      .min(1, "Please provide a preferred contact method")
      .of(yup.string().required())
      .required("Please provide a preferred contact method"),
    [PHONE_NUMBER]: yup
      .string()
      .when(PREFERRED_CONTACT_METHOD, (methodsArray = [], schema) => {
        const methods: string[] = methodsArray[0] || []
        return !isValidPhoneNumber && needsPhoneValidation(methods)
          ? schema
              .required("Please enter a phone number.")
              .matches(phoneRegex, "Please provide a valid phone number")
          : schema
      }),
    [QUESTION]: yup
      .string()
      .min(1, "Please provide a question")
      .max(100, "Question must be less than 100 characters")
      .required("Please provide a question"),
  })
