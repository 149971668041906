import { ColumnDef } from "@tanstack/react-table"
import { DateTime } from "luxon"
import cx from "classnames"
import * as yup from "yup"
import { startCase } from "lodash"

import { ProjectBadge } from "../../../components/ProjectBadge"
import { FILTER_FNS } from "./constants"
import {
  EligibleLeadFilterFunctionsTypes,
  QualifiedLeadFilterFunctionsTypes,
} from "../../../stores/usePartnersStore"
import { EligibleLeadTypes, RowType } from "../../../types/partners"
import { ProjectBadgeType } from "@/types/constants"
import { DialogStateReturn } from "reakit"
import { Profile } from "../../../types"
import IndeterminateCheckbox from "./IndeterminateCheckbox"

const emailSchema = yup.string().email()

export const isValidNewEmail = (inputValue: string) => {
  if (inputValue.length > 0) {
    try {
      emailSchema.validateSync(inputValue)
      return true
    } catch (error) {
      return false
    }
  } else {
    return false
  }
}

const newValues = ["new ", "new -", "new - "]

const inboundValues = [
  "new - i",
  "new - in",
  "new - inb",
  "new - inbo",
  "new - inbou",
  "new - inboun",
  "new - inbound",
]

const outboundValues = [
  "new - o",
  "new - ou",
  "new - out",
  "new - outb",
  "new - outbo",
  "new - outbou",
  "new - outboun",
  "new - outbound",
]

export const filterFns = {
  // Text fns
  [FILTER_FNS.includesString]: (
    row: { original: { [x: string]: any; unlocked_at: any } },
    columnId: string,
    filterValue: any
  ) => {
    const currentRow = (row.original[columnId] ?? "").toString()
    const value: string = (filterValue ?? "").toString().toLowerCase()

    if (columnId === "status" && outboundValues.includes(value)) {
      return (
        currentRow === "New" && typeof row.original.unlocked_at === "string"
      )
    }
    if (columnId === "status" && inboundValues.includes(value)) {
      return (
        currentRow === "New" && typeof row.original.unlocked_at !== "string"
      )
    }
    if (columnId === "status" && newValues.includes(value)) {
      return currentRow === "New"
    }

    return currentRow.toLowerCase().includes(value)
  },
  [FILTER_FNS.doesNotIncludeString]: (
    row: { original: { [x: string]: any; unlocked_at: any } },
    columnId: string,
    filterValue: any
  ) => {
    const currentRow = (row.original[columnId] ?? "").toString()
    const value: string = (filterValue ?? "").toString().toLowerCase()

    if (columnId === "status" && outboundValues.includes(value)) {
      return !(
        currentRow === "New" && typeof row.original.unlocked_at === "string"
      )
    }
    if (columnId === "status" && inboundValues.includes(value)) {
      return !(
        currentRow === "New" && typeof row.original.unlocked_at !== "string"
      )
    }
    if (columnId === "status" && newValues.includes(value)) {
      return currentRow !== "New"
    }

    return !currentRow.toLowerCase().includes(value)
  },
  [FILTER_FNS.weakEquals]: (
    row: { original: { [x: string]: any; unlocked_at: any } },
    columnId: string,
    filterValue: any
  ) => {
    const currentRow = (row.original[columnId] ?? "").toString()
    const value: string = (filterValue ?? "").toString().toLowerCase()

    if (columnId === "status" && value === "new - outbound") {
      return (
        currentRow === "New" && typeof row.original.unlocked_at === "string"
      )
    }
    if (columnId === "status" && value === "new - inbound") {
      return (
        currentRow === "New" && typeof row.original.unlocked_at !== "string"
      )
    }
    if (columnId === "status" && newValues.includes(value)) {
      return currentRow === "New"
    }

    return currentRow.toLowerCase() === value
  },
  [FILTER_FNS.isNot]: (
    row: { original: { [x: string]: any; unlocked_at: any } },
    columnId: string,
    filterValue: any
  ) => {
    const currentRow = (row.original[columnId] ?? "").toString()
    const value: string = (filterValue ?? "").toString().toLowerCase()

    if (columnId === "status" && value === "new - outbound") {
      return !(
        currentRow === "New" && typeof row.original.unlocked_at === "string"
      )
    }
    if (columnId === "status" && value === "new - inbound") {
      return !(
        currentRow === "New" && typeof row.original.unlocked_at !== "string"
      )
    }
    if (columnId === "status" && newValues.includes(value)) {
      return currentRow !== "New"
    }

    return currentRow.toLowerCase() !== value
  },
  [FILTER_FNS.isEmpty]: (
    row: { original: { [x: string]: string } },
    columnId: string | number
  ) => {
    return (
      row.original[columnId] === null ||
      row.original[columnId] === undefined ||
      row.original[columnId] === ""
    )
  },
  [FILTER_FNS.isNotEmpty]: (
    row: { original: { [x: string]: string } },
    columnId: string | number
  ) => {
    return (
      row.original[columnId] !== null &&
      row.original[columnId] !== undefined &&
      row.original[columnId] !== ""
    )
  },
  // Number fns
  [FILTER_FNS.lessOrEqual]: (
    row: { original: { [x: string]: number } },
    columnId: string | number,
    filterValue: any
  ) => {
    return row.original[columnId] <= Number(filterValue)
  },
  [FILTER_FNS.moreOrEqual]: (
    row: { original: { [x: string]: number } },
    columnId: string | number,
    filterValue: any
  ) => {
    return row.original[columnId] >= Number(filterValue)
  },
  [FILTER_FNS.notEqual]: (
    row: { original: { [x: string]: number } },
    columnId: string | number,
    filterValue: any
  ) => {
    return row.original[columnId] !== Number(filterValue)
  },
  // Date fns
  [FILTER_FNS.isBefore]: (
    row: { original: { [x: string]: number } },
    columnId: string | number,
    filterValue: number
  ) => {
    return row.original[columnId] < filterValue
  },
  [FILTER_FNS.isAfter]: (
    row: { original: { [x: string]: string } },
    columnId: string | number,
    filterValue: string
  ) => {
    const rowDate = DateTime.fromISO(row.original[columnId], { zone: "utc" })
    let filterDate = DateTime.fromISO(filterValue, { zone: "utc" })
    filterDate = filterDate.plus({ days: 1 })

    return rowDate.toMillis() > filterDate.toMillis()
  },
  [FILTER_FNS.isDate]: (
    row: { original: { [x: string]: string } },
    columnId: string | number,
    filterValue: string
  ) => {
    const rowDate = DateTime.fromISO(row.original[columnId], { zone: "utc" })
    const filterDate = DateTime.fromISO(filterValue, { zone: "utc" })

    const rowYear = rowDate.year
    const rowMonth = rowDate.month
    const rowDay = rowDate.day

    const filterYear = filterDate.year
    const filterMonth = filterDate.month
    const filterDay = filterDate.day

    return (
      rowYear === filterYear && rowMonth === filterMonth && rowDay === filterDay
    )
  },
  [FILTER_FNS.isNotDate]: (
    row: { original: { [x: string]: string } },
    columnId: string | number,
    filterValue: string
  ) => {
    const rowDate = DateTime.fromISO(row.original[columnId], { zone: "utc" })
    const filterDate = DateTime.fromISO(filterValue, { zone: "utc" })

    const rowYear = rowDate.year
    const rowMonth = rowDate.month
    const rowDay = rowDate.day

    const filterYear = filterDate.year
    const filterMonth = filterDate.month
    const filterDay = filterDate.day

    return !(
      rowYear === filterYear &&
      rowMonth === filterMonth &&
      rowDay === filterDay
    )
  },
}

const statusBadgeType = (value: string): ProjectBadgeType => {
  const statuses = {
    New: "primary",
    "Contract Signed": "primary",
    unlocked: "primary",
    pending: "neutral",
    "Conversation in Progress": "info",
    "Attempting Contact": "warning",
    "Awaiting More Land": "warning",
    hidden: "error",
    "Lost: Rejected Ineligible": "error",
    "Lost: Unresponsive": "error",
    "Lost: Offer Declined": "error",
    "Lost: Uninterested": "error",
    "Lost: Existing Relationship": "error",
    default: "primary",
  }

  return (statuses[value as keyof typeof statuses] ||
    statuses.default) as ProjectBadgeType
}

const statusLabel = (row: any) => {
  const status: string = row.original.status

  if (status === "New") {
    // row has been 'unlocked' by partner
    if (typeof row.original.unlocked_at === "string") {
      return "New - Outbound"
    }
    return "New - Inbound"
  }

  return startCase(status)
}

export const columnVisibility = {
  project_name: true,
  deal_owner: true,
  status: true,
  id: false,
  first_name: true,
  last_name: true,
  primary_property_state: true,
  acreage: true,
  eligible_acres: true,
  email: true,
  phone_number: true,
  legal_entity_name: true,
  interest_date: true,
}

export type QualifiedTableColumnType = ColumnDef<RowType[], any>

export const qualifiedTableColumns = (
  filterFunctions: QualifiedLeadFilterFunctionsTypes,
  inviteDealOwnerDialog: DialogStateReturn,
  editDealOwnerDialog: DialogStateReturn,
  setDealOwnerRowId: (id: number | null) => void,
  profile: Profile | undefined
): QualifiedTableColumnType[] => [
  {
    id: "select",
    header: ({ table }) => (
      <IndeterminateCheckbox
        checked={table.getIsAllRowsSelected()}
        indeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
    ),
    cell: ({ row }) => (
      <IndeterminateCheckbox
        checked={row.getIsSelected()}
        indeterminate={row.getIsSomeSelected()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
    filterFn: undefined,
    size: 60,
  },
  {
    id: "project_name",
    accessorKey: "project_name",
    header: "Program",
    cell: (props) => (
      <p
        className="whitespace-nowrap truncate max-w-full"
        title={props.getValue()}
      >
        {props.getValue()}
      </p>
    ),
    filterFn: filterFunctions.project_name,
    size: 300,
  },
  {
    id: "partner_name",
    accessorKey: "partner_name",
    header: "Partner Name",
    cell: (props) => {
      const values = props.getValue()
      return (
        <p
          className="truncate max-w-full"
          title={values.map((value: any) => value)}
        >
          {values.map((value: string, index: number) => (
            <span className="whitespace-nowrap " key={`${value}-${index}`}>
              {value}
              {index < values?.length - 1 ? ", " : null}
            </span>
          ))}
        </p>
      )
    },
    filterFn: filterFunctions.partner_name,
    size: 300,
    // @ts-ignore
    hidden: profile?.is_partner_assignee,
  },
  {
    id: "deal_owner",
    accessorKey: "deal_owner",
    header: "Deal Owner",
    cell: (props: any) => {
      const email = props.getValue()?.email
      const disabled =
        profile?.is_partner === false && profile?.is_service_provider === false

      const emailEmpty = email === undefined || email === null || email === ""
      const value = emailEmpty ? "Assign deal owner" : email

      return (
        <div className="w-full" title={email}>
          <button
            className={cx(
              "whitespace-nowrap truncate max-w-full",
              disabled ? "text-dusk font-semibold cursor-not-allowed" : "link"
            )}
            onClick={(e) => {
              e.stopPropagation()
              setDealOwnerRowId(props.row.original.id as number)
              if (value === email) {
                editDealOwnerDialog.show()
              } else {
                inviteDealOwnerDialog.show()
              }
            }}
            disabled={disabled}
          >
            {value}
          </button>
        </div>
      )
    },
    filterFn: filterFunctions.deal_owner,
    size: 300,
  },
  {
    id: "status",
    accessorKey: "status",
    header: "Status",
    cell: (props) => {
      const value: string = props.getValue()
      return (
        <div className="w-[170px]">
          <ProjectBadge type={statusBadgeType(value)} size="sm">
            <span className="whitespace-nowrap text-xs">
              {statusLabel(props.row)}
            </span>
          </ProjectBadge>
        </div>
      )
    },
    filterFn: filterFunctions.status,
    size: 300,
    sortingFn: (rowA, rowB, columnId) => {
      const statusOrder = {
        New: 1,
        "Attempting Contact": 2,
        "Conversation in Progress": 3,
        "Awaiting More Land": 4,
        "Contractual Offer Sent": 5,
        "Contract Signed": 6,
        "Lost: Existing Relationship": 7,
        "Lost: Offer Declined": 8,
        "Lost: Uninterested": 9,
        "Lost: Rejected Ineligible": 10,
        "Lost: Unresponsive": 11,
        default: 12,
      }
      const rowAStatus =
        statusOrder[
          rowA.original[
            columnId as keyof typeof rowA.original
          ] as unknown as keyof typeof statusOrder
        ] || statusOrder.default
      const rowBStatus =
        statusOrder[
          rowB.original[
            columnId as keyof typeof rowB.original
          ] as unknown as keyof typeof statusOrder
        ] || statusOrder.default

      // if the status' are the same, sort by interest date
      if (rowBStatus === rowAStatus) {
        const rowADate = rowA.getValue("interest_date")
        const rowBDate = rowB.getValue("interest_date")

        if (rowADate && rowBDate) {
          // both have interest dates - sort desc
          return rowADate > rowBDate ? 1 : rowADate < rowBDate ? -1 : 0
        }
        // only one has an interest date - make no interest date last
        return rowADate ? 1 : rowBDate ? -1 : 0
      }
      return rowBStatus - rowAStatus
    },
  },
  {
    id: "id",
    accessorKey: "id",
    header: "Account ID",
    cell: (props) => <p>{props.getValue()}</p>,
    filterFn: filterFunctions.id,
    size: 150,
  },
  {
    id: "first_name",
    accessorKey: "first_name",
    header: "First Name",
    cell: (props) => (
      <p
        className="whitespace-nowrap truncate max-w-full"
        title={props.getValue()}
      >
        {props.getValue()}
      </p>
    ),
    filterFn: filterFunctions.first_name,
    size: 150,
  },
  {
    id: "last_name",
    accessorKey: "last_name",
    header: "Last Name",
    cell: (props) => (
      <p
        className="whitespace-nowrap truncate max-w-full"
        title={props.getValue()}
      >
        {props.getValue()}
      </p>
    ),
    filterFn: filterFunctions.last_name,
    size: 150,
  },
  {
    id: "primary_property_state",
    accessorKey: "primary_property_state",
    header: "Primary Property State",
    cell: (props) => <p>{props.getValue()}</p>,
    filterFn: filterFunctions.primary_property_state,
    size: 180,
  },
  {
    id: "acreage",
    accessorKey: "acreage",
    header: "Acreage",
    cell: (props) => <p className="text-right w-full">{props.getValue()}</p>,
    filterFn: filterFunctions.acreage,
    size: 150,
  },
  {
    id: "eligible_acres",
    accessorKey: "eligible_acres",
    header: "Eligible Acres",
    cell: (props) => <p className="text-right w-full">{props.getValue()}</p>,
    filterFn: filterFunctions.eligible_acres,
    size: 150,
  },
  {
    id: "email",
    accessorKey: "email",
    header: "Email Address",
    cell: (props) => (
      <p className="whitespace-nowrap max-w-full" title={props.getValue()}>
        <a
          className="link inline-block truncate max-w-full"
          href={`mailto:${props.getValue()}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.getValue()}
        </a>
      </p>
    ),
    filterFn: filterFunctions.email,
    size: 300,
  },
  {
    id: "phone_number",
    accessorKey: "phone_number",
    header: "Phone Number",
    cell: (props) => <p className="whitespace-nowrap">{props.getValue()}</p>,
    filterFn: filterFunctions.phone_number,
    size: 150,
  },
  {
    id: "legal_entity_name",
    accessorKey: "legal_entity_name",
    header: "Legal Entity Name",
    cell: (props) => (
      <p
        className="whitespace-nowrap truncate max-w-full"
        title={props.getValue()}
      >
        {props.getValue()}
      </p>
    ),
    filterFn: filterFunctions.legal_entity_name,
    size: 150,
  },
  {
    id: "interest_date",
    accessorKey: "interest_date",
    header: "Interest Date",
    cell: (props) => {
      let formattedDate = props.getValue()

      if (props.getValue()) {
        const parsedDate = DateTime.fromISO(props.getValue() as string, {
          zone: "utc",
        })
        formattedDate = parsedDate.toFormat("LLL dd, yyyy")
      }

      return <p className="whitespace-nowrap">{formattedDate}</p>
    },
    filterFn: filterFunctions.interest_date,
    size: 150,
  },
  {
    id: "county",
    accessorKey: "county",
    header: "County",
    cell: (props) => (
      <p
        className="whitespace-nowrap truncate max-w-full"
        title={props.getValue()}
      >
        {props.getValue()}
      </p>
    ),
    filterFn: filterFunctions.county,
    size: 300,
  },
]

export type EligibleTableColumnType = ColumnDef<EligibleLeadTypes[], any>

export const eligibleTableColumns = (
  filterFunctions: EligibleLeadFilterFunctionsTypes
): EligibleTableColumnType[] => [
  {
    id: "project_name",
    accessorKey: "project_name",
    header: "Program",
    cell: (props) => (
      <p
        className="whitespace-nowrap truncate max-w-full"
        title={props.getValue()}
      >
        {props.getValue()}
      </p>
    ),
    filterFn: filterFunctions.project_name,
    size: 300,
  },
  {
    id: "status",
    accessorKey: "status",
    header: "Status",
    cell: (props) => {
      const value: string = props.getValue()
      return value !== undefined ? (
        <div>
          <ProjectBadge type={statusBadgeType(value)} size="sm">
            <span className="whitespace-nowrap text-xs">
              {statusLabel(props.row)}
            </span>
          </ProjectBadge>
        </div>
      ) : (
        <p>Hidden</p>
      )
    },
    filterFn: filterFunctions.status,
    size: 150,
    sortingFn: (rowA: any, rowB: any, columnId) => {
      const statusOrder = {
        unlocked: 1,
        pending: 2,
        "Attempting Contact": 3,
        "Conversation in Progress": 4,
        "Awaiting More Land": 5,
        "Contractual Offer Sent": 6,
        "Contract Signed": 7,
        "Lost: Existing Relationship": 8,
        "Lost: Offer Declined": 9,
        "Lost: Uninterested": 10,
        "Lost: Rejected Ineligible": 11,
        "Lost: Unresponsive": 12,
        default: 13,
      }
      const rowAStatus =
        statusOrder[
          rowA.original[
            columnId as keyof typeof rowA.original
          ] as unknown as keyof typeof statusOrder
        ] || statusOrder.default
      const rowBStatus =
        statusOrder[
          rowB.original[
            columnId as keyof typeof rowB.original
          ] as unknown as keyof typeof statusOrder
        ] || statusOrder.default
      // if the status' are the same, sort by id
      return rowBStatus - rowAStatus === 0
        ? rowB.original.id - rowA.original.id
        : rowBStatus - rowAStatus
    },
  },
  {
    id: "id",
    accessorKey: "id",
    header: "Account ID",
    cell: (props) => <p>{props.getValue()}</p>,
    filterFn: filterFunctions.id,
    size: 150,
  },
  {
    id: "first_name",
    accessorKey: "first_name",
    header: "First Name",
    cell: (props) => (
      <p
        className="whitespace-nowrap truncate max-w-full"
        title={props.getValue()}
      >
        {props.getValue()}
      </p>
    ),
    filterFn: filterFunctions.first_name,
    size: 150,
  },
  {
    id: "primary_property_state",
    accessorKey: "primary_property_state",
    header: "Primary Property State",
    cell: (props) => <p>{props.getValue()}</p>,
    filterFn: filterFunctions.primary_property_state,
    size: 180,
  },
  {
    id: "acreage",
    accessorKey: "acreage",
    header: "Acreage",
    cell: (props) => <p className="text-right w-full">{props.getValue()}</p>,
    filterFn: filterFunctions.acreage,
    size: 150,
  },
  {
    id: "eligible_acres",
    accessorKey: "eligible_acres",
    header: "Eligible Acres",
    cell: (props) => <p className="text-right w-full">{props.getValue()}</p>,
    filterFn: filterFunctions.eligible_acres,
    size: 150,
  },
]
