import { DialogStateReturn } from "reakit"

import ProjectCard from "../../../components/ProjectCard"
import { ProjectDataTypes } from "@/types/program"

interface ScoreCardDialogProgramsProps {
  programs: ProjectDataTypes[] | null
  dialog: DialogStateReturn
}

const ScoreCardDialogPrograms = ({
  programs,
  dialog,
}: ScoreCardDialogProgramsProps) => {
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-3">
        {!programs || programs.length === 0 ? (
          <div>
            No eligible programs available for your land at this time. Check
            back later!
          </div>
        ) : (
          programs.map((program) => (
            <ProjectCard
              key={program.id}
              programData={program}
              onClick={dialog?.hide}
              className={"scorecard"}
              showMinimalInfo={true}
            />
          ))
        )}
      </div>
    </div>
  )
}

export default ScoreCardDialogPrograms
