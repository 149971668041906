import ScoreCardHeading from "./ScoreCardHeading"
import ScoreCardItemIcon from "./ScoreCardItemIcon"
import ScoreCardSubHeading from "./ScoreCardSubHeading"
import ScoreCardDescription from "./ScoreCardDescription"
import ScoreCardHeadingPill from "./ScoreCardHeadingPill"
import ScoreCardScore from "./ScoreCardScore"
import { getScoreRating, getScoreColorClass } from "./helpers"
import { SCORE_CARDS } from "./constants"

interface ScoreCardItemProps {
  currentScore: number | null
  maxScore: number | null
  category: keyof typeof SCORE_CARDS
  hasScoreBarLegend?: boolean
}

const ScoreCardItem = ({
  currentScore,
  maxScore,
  category,
  hasScoreBarLegend = false,
}: ScoreCardItemProps) => {
  const scoreRating = getScoreRating(
    currentScore ? Math.round(currentScore) : 0
  )
  const scoreColorClass = getScoreColorClass(currentScore ?? 0)

  return (
    <div
      className={`scorecard-${category} flex flex-col sm:flex-row w-full justify-between gap-4 pt-4 sm:pt-0`}
    >
      <div>
        <ScoreCardHeading className="flex">
          <ScoreCardItemIcon icon={category} />

          {SCORE_CARDS[category].heading}

          <ScoreCardHeadingPill
            scoreColorClass={scoreColorClass}
            scoreRating={scoreRating}
          />
        </ScoreCardHeading>

        <ScoreCardSubHeading
          text={SCORE_CARDS[category].subheading(scoreRating)}
          className="mt-2.5"
        />

        <ScoreCardDescription
          text={SCORE_CARDS[category].description}
          className="mt-1.5 mb-3"
        />
      </div>

      <ScoreCardScore
        currentScore={currentScore}
        maxScore={maxScore}
        scoreColorClass={scoreColorClass}
        hasScoreBarLegend={hasScoreBarLegend}
      />
    </div>
  )
}

export default ScoreCardItem
