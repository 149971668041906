import { HelpCardContentTypes } from "./types"

const HelpCardContent = ({ items }: HelpCardContentTypes) => (
  <div className="space-y-4">
    {items.map((item, i) => (
      <div key={i}>
        <p className="mb-2">
          <strong>{item.title}</strong>
        </p>

        <div>{item.content}</div>
      </div>
    ))}
  </div>
)

export default HelpCardContent
