import { ReactElement } from "react"
import { Tooltip, TooltipArrow, TooltipStateReturn } from "reakit/Tooltip"

export interface TooltipContentTypes {
  children: ReactElement | string
  tooltip: TooltipStateReturn
}

const TooltipContent = ({ children, tooltip }: TooltipContentTypes) => (
  <Tooltip {...tooltip} className="st-tooltip">
    <div className="text-sm max-w-sm p-3 st-tooltip-inner">
      <TooltipArrow {...tooltip} />
      {children}
    </div>
  </Tooltip>
)

export default TooltipContent
