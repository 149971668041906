import Fireworks from "react-canvas-confetti/dist/presets/fireworks"

export interface FireworksConfettiProps {
  show: boolean
  speed: number
  duration: number
}

const FireworksConfetti = ({
  show,
  speed,
  duration,
}: FireworksConfettiProps) => {
  if (!show) {
    return null
  }

  return <Fireworks autorun={{ speed, duration }} />
}

export default FireworksConfetti
