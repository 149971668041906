import { useQueryClient } from "@tanstack/react-query"
import { useNavigate } from "react-router"

import MainLayout from "./_layouts/Main"
import FireworksConfetti from "../components/FireworksConfetti"
import { Spinner } from "../components/Spinner"
import { useAccount, useAccountId, useAccountUrlPrefix } from "../hooks"
import { useIsCheckoutSuccess } from "../hooks/useIsCheckoutSuccess"
import { AccountTypes } from "@/types/account"

const BookConsultation = () => {
  const queryClient = useQueryClient()
  const accountId = useAccountId()
  const accountUrlPrefix = useAccountUrlPrefix()
  const navigate = useNavigate()
  const isCheckoutSuccess = useIsCheckoutSuccess()

  const { data: account, isLoading: accountIsLoading } = useAccount<
    AccountTypes,
    Error
  >(queryClient, accountId, {
    refetchInterval: (query) => {
      if (isCheckoutSuccess && query.state.data?.is_member === false) {
        return 1000
      }

      return false
    },
  })

  const isHighAcreage = account?.subscription_pricing.nca_onetime === null

  // if user lands here outside stripe checkout flow and they don't have premium (NCA), redirect to the appropriate paywall
  if (!isCheckoutSuccess) {
    let redirectUrl
    let state
    if (account?.is_member === false) {
      redirectUrl = `${accountUrlPrefix}/become-a-member`
      state = { includeAdvisor: true }
    } else if (
      account?.is_member === true &&
      !account?.has_purchased_nca_report
    ) {
      redirectUrl = `${accountUrlPrefix}/advisor-signup`
    }

    if (redirectUrl) {
      navigate(redirectUrl, { replace: true, state })

      return (
        <div className="flex-auto flex items-center justify-center">
          <Spinner />
        </div>
      )
    }
  }

  return (
    <MainLayout isLoading={[accountIsLoading]} contentBg="white">
      <div className="container px-4 pt-[92px] pb-[132px]">
        <h1 className="text-4xl md:text-[50px] text-charcoal-500 text-center font-medium leading-[124%] md:leading-[110%] tracking-0.4 md:tracking-[1px]">
          Book your consultation
        </h1>

        <h2 className="text-autumn text-xl md:text-3xl text-center font-medium leading-[140%] md:leading-[124%] tracking-0.4 md:tracking-[0.6px] my-7">
          {`Thank you for your ${
            isHighAcreage ? "interest in" : "purchase of"
          } an expert plan`}
          <br />
          Book your initial consultation now.
        </h2>

        <iframe
          className="meetings-iframe-container"
          title={"hubspot"}
          width="100%"
          height="700px"
          src="https://meetings.hubspot.com/jen-drumm/nca-landowner-scheduling-link?embed=true"
        />
      </div>

      <FireworksConfetti show={isCheckoutSuccess} speed={3} duration={3000} />
    </MainLayout>
  )
}

export default BookConsultation
