import { useState, useRef, useEffect, ReactElement, useCallback } from "react"
import { useQueryClient } from "@tanstack/react-query"
import cx from "classnames"
import {
  useDialogState,
  DialogDisclosure,
  DialogStateReturn,
} from "reakit/Dialog"
import { FormikValues, useFormik } from "formik"
import * as Yup from "yup"
import { useCounter } from "react-use"
import bbox from "@turf/bbox"
import booleanWithin from "@turf/boolean-within"

import { Toast } from "../components/Toast"
import { Modal } from "../components/Modal"
import { AcreageToolTip } from "../components/AcreageToolTip"
import { MapVisualization } from "../components/MapVisualization/MapVisualization"
import { UploadProperty } from "../components/UploadProperty"
import { Spinner } from "../components/Spinner"
import { SubmitButton } from "../components/SubmitButton"
import { ActionPermissionWrapper } from "../components/ActionPermissionWrapper"
import ErrorCard from "../components/ErrorCard"
import { ButtonPair } from "../components/ButtonPair"
import {
  useAccountId,
  useAccountPropertyPreview,
  useUpdateAccountProperty,
  useAddressLatLng,
  useUserCurrentPosition,
} from "../hooks"
import { shortenAcreage, convertMultiPolygonToPolygons } from "../utils"
import { DEFAULT_ZOOM_LEVEL, getViewportFromFeatures } from "../shared/utils"
import { AccountTypes } from "../types/account"
import MapGL, { Viewport } from "@urbica/react-map-gl"
import {
  feature,
  Feature,
  Geometry,
  MultiPolygon,
  Polygon,
  Properties,
} from "@turf/helpers"
import { AccountProperty } from "@/types/property"
import { FeatureType } from "@/types"
import { MapGLRef } from "@/components/MapVisualization/types"

const unitedStatesOutlinePolygon = import(
  "../fixtures/united-states-outline-polygon.json"
)

type FeatureProperties = FeatureType
type FeaturesProperties = FeatureProperties[]

interface PropertyContainerTypes {
  hidden?: boolean
  account: AccountTypes
  initialFeatures: FeaturesProperties
  acreage: number
  backLink: ReactElement<any, any> | undefined
  submitText: string
  onSuccess: () => void
  uploadDialog: DialogStateReturn
}

const PropertyContainer = ({
  hidden = false,
  account,
  initialFeatures,
  acreage,
  backLink,
  submitText,
  onSuccess,
  uploadDialog,
}: PropertyContainerTypes) => {
  const mapRef = useRef<MapGL>(null)
  const accountId = useAccountId()
  const queryClient = useQueryClient()
  const [usPolygons, setUSPolygons] = useState<
    Feature<Polygon | MultiPolygon>[] | null
  >(null)

  // DEV: We built/tried to use a `useIsFetchingParcelData` but it caused spinner to rotate, stop, restart
  //   This was caused by `isFetching` status being set to `false` before `useAccountPropertyPreview` was invoked
  //   As a result, we'd need a `isFetchingParcelData` with `useState(bool)` anyway - so we're using counters instead
  //   https://github.com/ncx-co/ncapx-platform/blob/2c18b0165b9ea8cb5e71343262be124230b21000/src/hooks/index.js#L638-L652
  const [
    numParcelRequests,
    { inc: incrementNumParcelRequests, dec: decrementNumParcelRequests },
  ] = useCounter(0)

  // Viewport defaults hierarchy:
  // 1. Center of known initial features
  // 2. Center of property address
  // 3. User location with fallback to USA
  // (1) Center of known initial features
  const [viewport, setViewport] = useState<Viewport>(
    getViewportFromFeatures(initialFeatures) as Viewport
  )
  const [viewportResolved, setViewportResolved] = useState(
    initialFeatures.length !== 0
  )

  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  // DEV: Cache status to prevent render updates between multiple `await` calls

  const [isInEditMode, setIsInEditMode] = useState(true)

  // (2) Center of property address
  const propertyAddress = [
    account.property_address_line_1,
    account.property_address_line_2,
    account.property_city,
    account.property_state,
    account.property_zip_code,
  ]
    .filter((item) => !!item)
    .join(", ")

  // DEV: Technically this is a dependent query on `account` but that's likely cached during onboarding
  //   (only time when we lack features)
  const {
    data: propertyAddressLatLng,
    fetchStatus: propertyAddressLatLngFetchStatus,
  } = useAddressLatLng<{ lat: number; lng: number }, Error>(propertyAddress, {
    enabled: viewportResolved === false && propertyAddress !== "",
  })

  // (3) User location with fallback to USA
  const { data: userPosition, fetchStatus: userPositionFetchStatus } =
    useUserCurrentPosition<
      {
        latitude: number
        longitude: number
        default: boolean
      },
      Error
    >({
      enabled: viewportResolved === false,
    })

  // Resolver for viewport (1 -> 2 -> 3)
  useEffect(() => {
    // If we have already determined the viewport, stop early
    if (viewportResolved) {
      return
    }

    // If we're waiting on our property address, stop early
    if (propertyAddressLatLngFetchStatus === "fetching") {
      return
    }
    // If we have an address (+ status = success), use it
    if (propertyAddressLatLng) {
      setViewport((oldViewport) => ({
        latitude: propertyAddressLatLng.lat,
        longitude: propertyAddressLatLng.lng,
        zoom: oldViewport?.zoom || DEFAULT_ZOOM_LEVEL,
      }))
      setViewportResolved(true)
      return
    }

    // Otherwise, similar logic for userPosition
    // DEV: We only hit this logic branching after we've verified we cannot load based on features nor address
    if (userPositionFetchStatus === "fetching") {
      return
    }
    // DEV: We will always have a `userPosition` as `useUserCurrentPosition` provides USA as default on failure
    if (userPosition) {
      setViewport((oldViewport) => ({
        latitude: userPosition.latitude,
        longitude: userPosition.longitude,
        // When we have a default position (e.g. were blocked by ad block), show whole map
        zoom: userPosition.default
          ? 4
          : oldViewport?.zoom || DEFAULT_ZOOM_LEVEL,
      }))
    }
    setViewportResolved(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyAddressLatLngFetchStatus, userPositionFetchStatus])

  const saveEditsDialog = useDialogState({
    animated: true,
  })

  const { mutateAsync: updateProperty } = useUpdateAccountProperty(
    queryClient,
    accountId
  )

  // DEV: Load the US polygons asynchronously
  useEffect(() => {
    const loadUSPolygons = async () => {
      try {
        const unitedStatesOutline = await unitedStatesOutlinePolygon

        setUSPolygons(
          unitedStatesOutline.features as Feature<Polygon | MultiPolygon>[]
        )
      } catch (error) {
        console.error("Failed to load United States outline polygons:", error)
      }
    }

    loadUSPolygons()
  }, [])

  // DEV: Validation function to check if features are within the US
  const validateFeatures = useCallback(
    (features: Feature<Polygon>[]) => {
      if (!usPolygons) return false

      return features.every((featureGeometry) => {
        // DEV: Wrap geometry into a Feature
        const wrappedFeature = feature(featureGeometry.geometry)

        // DEV: Check if the feature is within any of the US polygons
        return usPolygons.some((usPolygon) =>
          booleanWithin(wrappedFeature, usPolygon)
        )
      })
    },
    [usPolygons]
  )

  const formik = useFormik({
    initialValues: {
      features: initialFeatures,
    },
    validationSchema: Yup.object({
      features: Yup.array()
        .min(1, "At least one feature is required")
        .required("Features are required"),
    }),
    // DEV: Avoid `enableReinitialize=true`, see https://app.asana.com/0/0/1200063952843264/f
    enableReinitialize: false,
    onSubmit: async (values: FormikValues) => {
      const features = values.features

      if (features.length === 0) {
        Toast.error("Property bounds must be added before continuing.")
        return
      }

      if (!validateFeatures(features as Feature<Polygon, Properties>[])) {
        Toast.error(
          "Some features are outside of the contiguous United States."
        )
        return
      }

      try {
        await updateProperty({
          features,
        })

        onSuccess()
      } catch (error: any) {
        setErrorMessage(
          (error?.detail as string) ||
            "Something went wrong, please try again or reach out to us at landowners@ncx.com"
        )
      }
    },
  })

  // Request preview data when the features have changed
  // DEV: We technically don't need to use `enabled` + fallbacks (in fact, it can break in interesting ways)
  //   but it's good to use preloaded data
  const shouldFetchPreview = formik.dirty && formik.values.features.length !== 0
  const { data: _propertyPreview, status: _propertyPreviewStatus } =
    useAccountPropertyPreview<AccountProperty, Error>(
      queryClient,
      accountId,
      {
        features: formik.values.features,
      },
      {
        enabled: shouldFetchPreview,
      }
    )

  let propertyPreviewData

  if (shouldFetchPreview) {
    propertyPreviewData = _propertyPreview
  } else if (!formik.isValid) {
    propertyPreviewData = { acreage: 0 }
  } else {
    propertyPreviewData = { acreage }
  }

  const propertyPreviewStatus = shouldFetchPreview
    ? _propertyPreviewStatus
    : "success"

  const handleAddBounds = ({ features }: { features: FeaturesProperties }) => {
    const originalFeatures = [...formik.values.features]
    const updatedFeatures = [...formik.values.features, ...features]

    // Attempt to add our uploaded bounds
    try {
      formik.setFieldValue("features", updatedFeatures)
      if (mapRef.current) {
        const map = mapRef.current.getMap()
        map.fitBounds(
          bbox({
            type: "FeatureCollection",
            features: updatedFeatures,
          }),
          { padding: 40 }
        )
      }
    } catch (err) {
      // Revert on failure
      // DEV: We require revert, otherwise features will have bad value for preview estimate
      formik.setFieldValue("features", originalFeatures)
      // DEV: Error will be handled by `UploadProperty` modal
      throw err
    }
  }

  const handleCreateFeatures = (features: FeaturesProperties) => {
    formik.setFieldValue("features", [...formik.values.features, ...features])
  }

  // DEV: `update` will sometimes come back with a feature with only 2 points, it gets a `delete` call shortly after
  //   but we still fire a failing HTTP request before then =/
  //   https://app.asana.com/0/1199976942355619/1200117645347666/f
  const filterEmptyFeatures = (features: Feature<Geometry, Properties>[]) => {
    return features.filter((feature) => feature.geometry.coordinates.length > 0)
  }
  const ensurePolygon = (features: FeaturesProperties) => {
    // if none MultiPolygons, return directly to avoid unnecessary mapping
    if (!features.some((feature) => feature.geometry.type === "MultiPolygon"))
      return features

    return features.map(convertMultiPolygonToPolygons).flat()
  }
  const handleUpdateFeatures = (updatedFeatures: FeaturesProperties) => {
    const features: FeaturesProperties = formik.values.features.map(
      (currFeature: FeatureProperties) => {
        // DEV: If this becomes inefficient (n*m; m should always be 10 at most), then switch to set/hash lookup (n*1)
        const updatedFeature = updatedFeatures.find(
          (updatedFeature) => updatedFeature.id === currFeature.id
        )
        return updatedFeature || currFeature
      }
    )
    formik.setFieldValue(
      "features",
      filterEmptyFeatures(
        ensurePolygon(features) as Feature<Geometry, Properties>[]
      )
    )
  }

  const handleDeleteFeatures = (deletedFeatures: any[]) => {
    const features = formik.values.features.filter(
      (currFeature: { id: string }) => {
        // DEV: If this becomes inefficient (n*m; m should always be 10 at most), then switch to set/hash lookup (n*1)
        const wasDeleted = deletedFeatures.find(
          (deletedFeature) => deletedFeature.id === currFeature.id
        )
        return !wasDeleted
      }
    )
    formik.setFieldValue("features", features)
  }

  const handleClearFeatures = () => {
    formik.setFieldValue("features", [])
  }

  const showAcreageSpinner = Boolean(
    [propertyPreviewStatus].includes("pending") || numParcelRequests
  )

  const propertyBoundariesModalTitle = "Did you add all of your land?"

  return (
    <>
      {/* DEV: We want hidden support for /onboarding/property-boundaries (persist features on same page "back"/forward) */}
      {/* DEV: Use `invisible` so we get proper sizing for map, even if hidden */}
      {/* DEV: `z-index: -1` is insurance for any unexpected z-index issues from `position: absolute` */}
      <div
        className={cx("absolute inset-0 z-10 bg-white", {
          invisible: hidden,
          "-z-1": hidden,
        })}
      >
        {/* DEV: Use `flex-col` to avoid Safari not occupying full vertical height, though doesn't seem to repro like Auth layout did */}
        <div className="h-full flex flex-col">
          <div className="flex flex-col flex-auto">
            {viewportResolved === false ? (
              <div className="flex flex-auto items-center justify-center">
                <Spinner />
              </div>
            ) : (
              <MapVisualization
                ref={mapRef as MapGLRef}
                features={formik.values.features}
                incrementNumParcelRequests={incrementNumParcelRequests}
                decrementNumParcelRequests={decrementNumParcelRequests}
                viewport={viewport}
                setViewport={setViewport}
                onViewportChange={setViewport}
                onFeatureCreate={handleCreateFeatures}
                onFeatureUpdate={handleUpdateFeatures}
                onFeatureDelete={handleDeleteFeatures}
                onFeatureClear={handleClearFeatures}
                showParcelSelect={true}
                showParcelDraw={true}
                showParcelUpload={true}
                uploadDialog={uploadDialog}
                displayMapEditingTools={isInEditMode}
              />
            )}
          </div>

          <div className="shrink-0 flex flex-row flex-wrap items-center p-4  sm:p-6">
            <div className="hidden sm:block">
              <a
                href="https://help.ncx.com/hc/en-us/articles/9735031652891-How-do-I-map-out-my-property-boundaries-"
                target="_blank"
                rel="noopener noreferrer"
                className="text-leaf px-1"
              >
                <i
                  className="fas fa-question-circle p-1"
                  aria-hidden="true"
                ></i>
                Help
              </a>
            </div>

            <div className="sm:ml-auto">
              <div className="inline-block mr-8">
                <div className="font-light text-2xl leading-6">
                  {showAcreageSpinner ? (
                    <i className="fas fa-sync-alt fa-spin text-smoke"></i>
                  ) : [propertyPreviewStatus].includes("error") ? (
                    <span className="text-fire">Error</span>
                  ) : (
                    shortenAcreage(propertyPreviewData?.acreage || 0)
                  )}
                </div>
                <div>
                  total acres <AcreageToolTip />
                </div>
              </div>
            </div>

            <div className="w-full mt-4 sm:w-auto lg:mt-0 lg:ml-6">
              {/* https://reactjs.org/docs/react-api.html#cloneelement */}
              {backLink && (
                <backLink.type
                  {...backLink.props}
                  className="btn2 btn2-outline-primary font-semibold py-7px mr-2"
                />
              )}
              <ActionPermissionWrapper
                accountRole={account.role}
                action="editAccount"
              >
                {!isInEditMode ? (
                  <button
                    type="button"
                    onClick={() => setIsInEditMode(true)}
                    className="btn2 btn2-primary inline-block font-semibold"
                  >
                    Edit Boundaries
                  </button>
                ) : (
                  <DialogDisclosure
                    {...saveEditsDialog}
                    className="btn2 btn2-primary font-semibold"
                    // DEV: Use `showAcreageSpinner` to make `disabled` same as its spinner
                    //   Although we only need `numParcelRequests` to avoid dropping in-progress parcels on submit
                    disabled={showAcreageSpinner || !formik.isValid}
                  >
                    {submitText}
                  </DialogDisclosure>
                )}
              </ActionPermissionWrapper>
            </div>
          </div>
        </div>
      </div>
      <Modal
        header="Upload your property file"
        aria-label="Upload your property file"
        dialog={uploadDialog}
      >
        <UploadProperty
          onAddBoundsToMap={handleAddBounds}
          onSuccess={uploadDialog.hide}
        />
      </Modal>
      {/* DEV: `isInEditMode` conditional provided to avoid logic for status for `bid_accepted` and beyond */}
      {isInEditMode && (
        <Modal
          header={propertyBoundariesModalTitle}
          aria-label={propertyBoundariesModalTitle}
          className="px-6 py-6 max-w-3xl"
          dialog={saveEditsDialog}
          onDismiss={() => setErrorMessage(null)}
        >
          {errorMessage && (
            <ErrorCard className="mb-4">{errorMessage}</ErrorCard>
          )}

          <div className="space-y-p">
            <p>
              To get a total assessment of your eligibility for programs, we
              encourage you to add all of your landholdings. Need help adding
              more than one parcel?{" "}
              <a
                href="https://ncxcarbon.wistia.com/medias/edbhm5khsu"
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                Watch the tutorial
              </a>
              .
            </p>
          </div>

          <ButtonPair
            className="mt-6"
            // eslint-disable-next-line react/no-unstable-nested-components
            primary={(primaryProps) => (
              <SubmitButton
                isSubmitting={formik.isSubmitting}
                onClick={formik.handleSubmit}
                {...primaryProps}
              >
                Save and Continue
              </SubmitButton>
            )}
            secondary={
              <DialogDisclosure
                {...saveEditsDialog}
                className="btn2 btn2-outline-primary font-bold"
              >
                Add More Land
              </DialogDisclosure>
            }
          />
        </Modal>
      )}
    </>
  )
}

export default PropertyContainer
