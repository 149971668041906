import { ReactNode } from "react"
import cx from "classnames"

export interface SidebarTypes {
  title: string
  description: string
  children: ReactNode
  className?: string
}

const Sidebar = ({ title, description, children, className }: SidebarTypes) => (
  <div
    className={cx(
      "border border-solid border-grass-200 rounded w-full lg:w-396 overflow-hidden self-start",
      className
    )}
    data-testid="sidebar"
  >
    <div className="bg-grass-50 border-b border-b-solid border-b-grass-200">
      <h4 className="px-4 pt-4 text-grass-500 text-xl">{title}</h4>

      <div className="px-4 pb-4 pt-1 text-grass-500 text-base">
        {description}
      </div>
    </div>

    <div className="p-4">{children}</div>
  </div>
)

export default Sidebar
