export type BadgeDisplayType =
  | "eligible"
  | "ineligible"
  | "not_interested"
  | "request_information"
  | "determining_eligibility"
  | "under_contract"
  | "information_needed"
  | "datapack_shareable"
  | "lo_not_interested"
  | "internal_dq"

export type PartnerAccountStatusType =
  | "pd_attempting_contact"
  | "awaiting_more_land"
  | "under_contract"
  | "pd_contract_sent"
  | "pd_contacted"
  | "pd_existing_relationship"
  | "lo_offer_declined"
  | "pd_not_accepted"
  | "lo_not_interested"
  | "lo_unresponsive"

export enum ProgramCategoryType {
  tree_planting = "tree_planting",
  renewable_infrastructure = "renewable_infrastructure",
  timber = "timber",
  recreation = "recreation",
  harvest_deferral = "harvest_deferral",
  forest_carbon = "forest_carbon",
  water = "water",
  biodiversity = "biodiversity",
  wildfire = "wildfire",
  regen_ag = "regen_ag",
  advisory_services = "advisory_services",
  other = "other",
}

export type EligibilityQuizCategoryType =
  | "forest_carbon"
  | "tree_planting"
  | "timber"
  | "wildfire"
  | "renewable_infrastructure"
  | "recreation"
  | "regen_ag"
  | "advisory_services"
  | "general"

export const ValidQuizCategories: EligibilityQuizCategoryType[] = [
  "forest_carbon",
  "tree_planting",
  "timber",
  "wildfire",
  "renewable_infrastructure",
  "recreation",
  "regen_ag",
  "advisory_services",
  "general",
]

export type USStateAbbrType =
  | "AL"
  | "AK"
  | "AZ"
  | "AR"
  | "CA"
  | "CO"
  | "CT"
  | "DE"
  | "DC"
  | "FL"
  | "GA"
  | "HI"
  | "ID"
  | "IL"
  | "IN"
  | "IA"
  | "KS"
  | "KY"
  | "LA"
  | "ME"
  | "MD"
  | "MA"
  | "MI"
  | "MN"
  | "MS"
  | "MO"
  | "MT"
  | "NE"
  | "NV"
  | "NH"
  | "NJ"
  | "NM"
  | "NY"
  | "NC"
  | "ND"
  | "OH"
  | "OK"
  | "OR"
  | "PA"
  | "RI"
  | "SC"
  | "SD"
  | "TN"
  | "TX"
  | "UT"
  | "VT"
  | "VA"
  | "WA"
  | "WV"
  | "WI"
  | "WY"

export type USStateFullType =
  | "Alaska"
  | "Alabama"
  | "Arkansas"
  | "Arizona"
  | "California"
  | "Colorado"
  | "Connecticut"
  | "District of Columbia"
  | "Delaware"
  | "Florida"
  | "Georgia"
  | "Hawaii"
  | "Iowa"
  | "Idaho"
  | "Illinois"
  | "Indiana"
  | "Kansas"
  | "Kentucky"
  | "Louisiana"
  | "Massachusetts"
  | "Maryland"
  | "Maine"
  | "Michigan"
  | "Minnesota"
  | "Missouri"
  | "Mississippi"
  | "Montana"
  | "North Carolina"
  | "North Dakota"
  | "Nebraska"
  | "New Hampshire"
  | "New Jersey"
  | "New Mexico"
  | "Nevada"
  | "New York"
  | "Ohio"
  | "Oklahoma"
  | "Oregon"
  | "Pennsylvania"
  | "Rhode Island"
  | "South Carolina"
  | "South Dakota"
  | "Tennessee"
  | "Texas"
  | "Utah"
  | "Virginia"
  | "Vermont"
  | "Washington"
  | "Wisconsin"
  | "West Virginia"
  | "Wyoming"

export type ProjectBadgeType =
  | "primary"
  | "warning"
  | "error"
  | "neutral"
  | "info"

export type IneligibilityReasonsType =
  | "not in project geography"
  | "not enough acres meet minimum requirements"
  | "no part of the property meets the project requirements"

export type NotInterestedReason =
  | "key_obligation"
  | "term_length"
  | "landowner_costs"
  | "eligibility_requirements"
  | "earnings_and_penalties"
  | "restrictions"
  | "landowner_rights"
  | "property_access"
  | "other"
