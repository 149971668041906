import OnboardingOverviewAssets from "./OnboardingOverviewAssets"
import OnboardingOverviewScores from "./OnboardingOverviewScores"
import OnboardingOverviewPrograms from "./OnboardingOverviewPrograms"
import { ProjectDataTypes } from "@/types/program"

interface OnboardingOverviewStepsProps {
  overviewStep: number
  handleOverviewStep: () => void
  programsData: ProjectDataTypes[]
}

const OnboardingOverviewSteps = ({
  overviewStep,
  handleOverviewStep,
  programsData,
}: OnboardingOverviewStepsProps) => {
  if (overviewStep === 1) {
    return <OnboardingOverviewAssets handleOverviewStep={handleOverviewStep} />
  }

  if (overviewStep === 2) {
    return <OnboardingOverviewScores handleOverviewStep={handleOverviewStep} />
  }

  return <OnboardingOverviewPrograms programsData={programsData} />
}

export default OnboardingOverviewSteps
