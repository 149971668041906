interface ScoreCardHeaderProps {
  subheader: string | null
}
const ScoreCardHeader = ({ subheader }: ScoreCardHeaderProps) => (
  <>
    <h4 className="text-overline inline-block">
      Your Natural Capital Portfolio
    </h4>
    <span className="text-autumn italic ml-2">[Early Access]</span>

    <p className="text-charcoal-500 text-base leading-130 tracking-0.32 mt-2 mb-4">
      {subheader}
    </p>
  </>
)

export default ScoreCardHeader
