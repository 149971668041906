import { useQueryClient } from "@tanstack/react-query"

import { Notification } from "../../../components/Notification"
import { Toast } from "../../../components/Toast"
import { NOTIFICATIONS } from "../../../shared/constants"
import { genericErrMsg } from "../../../api/auth"
import { useDeleteAccountNotification } from "../../../hooks"
import SwitchToMultiAccountButton from "../NotificationContent/SwitchToMultiAccountButton"
import { NotificationTypes } from "@/types/notifications"

interface AccountNotificationTypes {
  accountId: string
  notification: NotificationTypes
}

const AccountNotification = ({
  accountId,
  notification,
}: AccountNotificationTypes) => {
  const queryClient = useQueryClient()

  const { mutate: deleteNotification } = useDeleteAccountNotification(
    queryClient,
    accountId
  )
  const onDismiss = () => deleteNotification(notification.id.toString())

  if (notification.key === NOTIFICATIONS.invite_inaccessible) {
    return (
      <Notification
        key={NOTIFICATIONS.invite_inaccessible}
        title="Invited to additional accounts"
      >
        <div className="space-y-2">
          <p>
            You've been invited to access additional accounts. To view these,
            you must become a multi-account user. This will update your NCX
            interface.
          </p>

          <p>Would you like to become a multi-account user?</p>

          <div>
            <button
              type="button"
              className="btn2 btn2-outline-primary font-semibold mt-3 mr-2 mb-2"
              onClick={onDismiss}
            >
              No, reject the invites
            </button>

            <SwitchToMultiAccountButton
              onError={(error: Error) => {
                // DEV: We're skipping <ErrorCard> handling here, Toast is a "good enough" stand-in
                Toast.error(error?.message || genericErrMsg)
              }}
              className="btn2 btn2-primary font-semibold"
            >
              Yes, become a multi-account user
            </SwitchToMultiAccountButton>
          </div>
        </div>
      </Notification>
    )
  }

  return null
}

export default AccountNotification
