import { useContext } from "react"
import { VisibilityContext } from "react-horizontal-scrolling-menu"
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons"
import SimilarProgramsSliderArrow from "./SimilarProgramsSliderArrow"

const SimilarProgramsSliderArrowLeft = () => {
  const { scrollPrev } = useContext(VisibilityContext)

  return (
    <SimilarProgramsSliderArrow
      onClick={() => scrollPrev()}
      icon={faArrowLeft}
    />
  )
}
export default SimilarProgramsSliderArrowLeft
