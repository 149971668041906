import cx from "classnames"

interface ScoreCardDescriptionProps {
  text: string
  className?: string
}

const ScoreCardDescription = ({
  text,
  className,
}: ScoreCardDescriptionProps) => (
  <p
    className={cx(
      "text-dusk-700 text-xs text-left font-body leading-130 tracking-0.36",
      className
    )}
  >
    {text}
  </p>
)

export default ScoreCardDescription
