export interface ProjectCardSponsoredBadgeProps {
  isSponsored: boolean
}

const ProjectCardSponsoredBadge = ({
  isSponsored,
}: ProjectCardSponsoredBadgeProps) => {
  if (!isSponsored) {
    return null
  }

  return (
    <h3 className="inline-block text-autumn text-sm font-overline font-medium leading-[21px] tracking-[1.68px] uppercase ml-2">
      Sponsored
    </h3>
  )
}

export default ProjectCardSponsoredBadge
