import { WrappedInput } from "../../components/Input"

interface PhoneInputProps {
  showPhoneField: boolean
}

const PhoneInput = ({ showPhoneField }: PhoneInputProps) => {
  if (!showPhoneField) {
    return null
  }

  return (
    <div>
      <WrappedInput
        labelClass="label font-bold mb-2 mt-2 leading-18px"
        aria-label="Phone number"
        label="Phone number"
        placeholder="Phone number"
        type="text"
        name="phone_number"
      />
    </div>
  )
}

export default PhoneInput
