import cx from "classnames"

interface AccountStatusTypes {
  isMember: boolean | undefined
  memberSince?: string | null
}

const AccountStatus = ({ isMember, memberSince }: AccountStatusTypes) => {
  return (
    <div className="mb-6">
      <h4 className="text-overline">Account status</h4>

      <p
        className={cx(
          "text-base leading-[140%] tracking-0.32 mt-2 text-charcoal-500"
        )}
      >
        {isMember ? `Member since ${memberSince}` : "Non-Member Free Tier"}
      </p>
    </div>
  )
}

export default AccountStatus
