import { ReactNode, useRef } from "react"
import { useMenuState, Menu, MenuItem, MenuButton } from "reakit/Menu"
import cx from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons"

type PlacementType =
  | "auto-start"
  | "auto"
  | "auto-end"
  | "top-start"
  | "top"
  | "top-end"
  | "right-start"
  | "right"
  | "right-end"
  | "bottom-end"
  | "bottom"
  | "bottom-start"
  | "left-end"
  | "left"
  | "left-start"

interface OptionObject {
  className?: string
  label: ReactNode
  value: string
}

export type OptionType = string | OptionObject

export interface SelectMenuTypes {
  options: OptionType[]
  label: string
  showSelected?: boolean
  selectedOption?: string
  setSelectedOption: (e: OptionType) => void
  onSelect?: () => void
  className?: string
  menuClassName?: string
  gutter?: number
  placement?: PlacementType
  fullWidthMenu?: boolean
}

const SelectMenu = ({
  options = [],
  label,
  showSelected = false,
  selectedOption,
  setSelectedOption,
  onSelect,
  className = "",
  menuClassName = "",
  gutter = 0,
  placement = "bottom-start",
  fullWidthMenu = false,
}: SelectMenuTypes) => {
  const dropdownRef = useRef<HTMLButtonElement>(null)
  const menu = useMenuState({
    loop: true,
    modal: true,
    gutter,
    placement,
  })

  const handleSelectOption = (value: OptionType) => () => {
    setSelectedOption(value)
    menu.hide()
    if (onSelect) {
      onSelect()
    }
  }

  const hasLabel = typeof label === "string"

  return (
    <>
      <MenuButton
        {...menu}
        ref={dropdownRef}
        className={cx(
          "flex justify-between text-base text-charcoal-500 bg-cloud-50 rounded border border-solid border-dusk-100 p-1.75 cursor-pointer",
          className
        )}
        aria-label="Dropdown menu button"
      >
        {hasLabel ? label : null}

        {showSelected ? (
          <span className={cx("font-bold mr-auto", hasLabel ? "ml-1" : "ml-0")}>
            {selectedOption ||
              (typeof options[0] === "string"
                ? options[0]
                : options[0].value) ||
              null}
          </span>
        ) : null}

        <span className="inline-block ml-1 w-6 leading-6 text-center">
          <FontAwesomeIcon icon={faChevronDown} className="fa-chevron-down" />
        </span>
      </MenuButton>

      <Menu
        {...menu}
        aria-label="Dropdown menu"
        className={cx(
          "rounded-lg bg-cloud-50 shadow-dropdown overflow-hidden flex flex-col z-10",
          menuClassName
        )}
        style={{
          width: fullWidthMenu ? dropdownRef?.current?.clientWidth : "auto",
        }}
        preventBodyScroll={false}
      >
        {options.map((option) => {
          const optionValue = typeof option === "string" ? option : option.value
          const optionLabel = typeof option === "string" ? option : option.label
          const optionClassName =
            typeof option === "string" ? "" : option.className

          return (
            <MenuItem
              {...menu}
              key={optionValue}
              aria-label={optionValue}
              className={cx(
                `text-start p-2 cursor-pointer hover:bg-grass-50 focus:bg-grass-50 focus:ring-0 ${optionClassName}`,
                { "bg-grass-50": selectedOption === optionValue }
              )}
              onClick={handleSelectOption(option)}
            >
              {optionLabel}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

export default SelectMenu
